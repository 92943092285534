@import url(./assets/fonts/GolosText/stylesheet.css);

body {
  margin: 0;
  font-family: 'Golos Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: 'Golos Text', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-color: #F4F4FA;
  --secondary-bg-color: #FFFFFF;
  --header-bg-color: #111859;
  --platform-bg-color: #E3E3F2;
  --second-level-header: rgba(255, 255, 255, 0.08);

  --auth-placeholder-bg-color: #111859;
  --auth-bg-shape-color: rgba(229, 228, 238, 0.70);
  --auth-form-bg-shape-color: #172181;

  --text-color: #505A72;
  --text-negative-color: #725050;
  --header-text-color: #E4E6EA;

  --table-title-color: #969CAA;
  --table-separator-color: #F4F4FA;

  --contrast-color: #5F35D7;
  --text-on-contrast-color: #FFFFFF;

  --secondary-contrast-color: #ECEFF2;
  --secondary-text-on-contrast-color: #FFFFFF;

  --btns-bg-color: rgba(117, 189, 255, 0.24);


  --input-label-color: #969CAA;
  --input-label-secondary-color: #0033AA;
  --input-border-color: rgba(102, 111, 131, 0.30);
  --input-select-bg-color: rgba(80, 90, 114, 0.08);
  --input-select-selected-bg-color: rgba(80, 90, 114, 0.3);
  --input-color-bg-color: rgba(80, 90, 114, 0.16);
  --input-dropdown-bg-color: #F1F2F4;


  --popup-bg-color: rgba(80, 90, 114, 0.6);
  --popup-bg-color-darker: rgba(80, 90, 114, 0.9);
  --popup-controls-bg-color: rgba(102, 111, 131, 0.16);

  --controls-bg-color: rgba(102, 111, 131, 0.16);

  --action-control-type-link-bg: rgba(80, 90, 114, 0.76);

  --error-color: #AA0000;
  --ion-green: #72b00e;

  --box-shadow-color: rgba(150, 152, 171, 0.23);

  --product-form-btn-text-color: #002272;
  --product-form-btn-bg-color: rgba(0, 34, 114, 0.08);

  --boolean-selector-false-text-color: #002272;
  --boolean-selector-false-bg-color: rgba(0, 34, 114, 0.08);
  --boolean-selector-true-text-color: #002272;
  --boolean-selector-true-bg-color: rgba(0, 34, 114, 0.30);

  --service-container-border-color: rgba(80, 90, 114, 0.16);

  --two-factor-btn-bg-color: #F4F1F1;
  --two-factor-btn-text-color: #725050;

  --example-card-text-color: #282B3C;
  --example-card-bg-color: #C0C0C0;
  --example-card-price-color: #A514FF;
  --example-card-btn-color: #1488FF;

  --category-btn-color: rgba(80, 90, 114, 0.08);
  --category-bg-color: rgba(80, 90, 114, 0.16);
  --category-bg-active-color: rgba(80, 90, 114, 0.76);
  --category-active-link-color: rgba(255, 255, 255, 0.16);
  --category-text-color: #C0C4CC;

  --avatar-placeholder-bg-color: #A1AEF7;
  --avatar-placeholder-text-color: #FFF;

  --shop-link-bg-color: #E7E7F7;
  --shop-link-text-color: #0030DA;

  --drop-separator-bg-color: rgba(80, 90, 114, 0.16);
  --drop-hover-bg-color: #EEF7FF;

  --violet-light-bg: #7E5EFF;
  --grey-light-border: #565F89;
  --text-error: #006ED4;

  --finance-subscription-bg-info: #F8F9F9;
  --finance-separator-color: rgba(94, 56, 207, 0.16);

  --selected-payment-bg: #F8F8FF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Golos Text', sans-serif;
  -webkit-box-shadow: 0 0 0 500px var(--secondary-bg-color) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--text-color) !important;
  background-color: var(--secondary-bg-color) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--text-color);
}

p, input, textarea {
  font-family: 'Golos Text', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Golos Text', sans-serif;
}

a {
  text-decoration: none;
  color: var(--text-color);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


button {
  font-family: 'Golos Text', sans-serif;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.2s linear;
}

/* 
button:hover {
  opacity: 0.8;
}

a{
  transition: all 0.2s linear;
}

a:hover{
  opacity: 0.8;
} */

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 0 18px;
    box-sizing: border-box;
    overflow: hidden;
}

.auth__form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: 680px;
    width: 100%;
    min-height: 680px;
}

/* .auth__bg {
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 16px;
    max-width: 1200px;
} */


.auth__bg-shape {
    position: absolute;
    z-index: 0;
    transition: all 0.2s linear;
}

.auth__bg-shape_type_1 {
    width: 261px;
    height: 151px;
    top: 175px;
    left: -215px;
}

.auth__bg-shape_type_2 {
    width: 887px;
    height: 513px;
    top: 364px;
    left: -793px;
}

.auth__bg-shape_type_3 {
    width: 133px;
    height: 77px;
    top: 467px;
    right: calc(-133px - 80px);
}

.auth__bg-shape_type_4{
    width: 1340px;
height: 774px;
top: -384px;
right: -670px;
}

.auth__bg-shape-fill {
    fill: var(--auth-bg-shape-color);
}

@media (max-width: 800px) {
    /* .auth__bg {
        display: none;
    } */
}
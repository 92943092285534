.product-form-placeholder {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.product-form-placeholder__icon {
    width: 100px;
    height: 108px;
}

.product-form-placeholder__icon-fill {
    fill: var(--text-color);
}

.product-form-placeholder__text {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 14.3px */
    letter-spacing: -0.78px;
}

.product-form-placeholder__create{
width: fit-content;
}
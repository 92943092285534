.action-btn {
    width: 100%;
    padding: 14px 16px;
    box-sizing: border-box;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    border-radius: 8px;
    background-color: var(--input-select-bg-color);
    transition: opacity 0.2s ease-in-out;
    color: var(--text-color);
}

.action-btn_type_contrast {
    color: var(--text-on-contrast-color);
    background-color: var(--contrast-color);
}

.action-btn__inactive {
    opacity: 0.3;
    pointer-events: none;
    cursor: no-drop;
    user-select: none;
}

.action-btn__loading{
    pointer-events: none;
}
.submit-act-popup {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.submit-act-popup_active {
  visibility: visible;
}


.submit-act-popup__container {
  width: 392px;

  background: var(--secondary-bg-color);
  z-index: 1001;
  position: relative;
  /* padding-bottom: 70px;
	transform: translateY(70px); */
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.2s linear;
  border: 1px solid var(--table-separator-color);
  box-sizing: border-box;
  border-radius: 12px;
  padding-bottom: 10px;
}

.submit-act-popup__container_active {
  opacity: 1;
}

.submit-act-popup__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--popup-bg-color);
  opacity: 0;
  z-index: 999;
  transition: opacity 0.4s linear;
}

.submit-act-popup__background_active {
  opacity: 1;
  transition: opacity 0.4s linear;
}

.submit-act-popup__close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* width: 24px;
  height: 24px; */
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  margin: 16px 16px 0 auto;
}

.submit-act-popup__close-icon{
  width: 24px;
  height: 24px;
}

.submit-act-popup__close-icon-stroke{
  stroke: var(--text-color);
}


.submit-act-popup__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 14.3px */
  letter-spacing: -0.78px;
  color: var(--text-color);
  margin: -24px 40px 0 16px;
}

.submit-act-popup__text b{
  font-weight: 600;
}

.submit-act-popup__btns {
  display: grid;
  grid-template-columns: repeat(2, minMax(0, 1fr));
  grid-template-rows: max-content;
  column-gap: 12px;
  flex-direction: column;
  margin: 16px 16px 6px;
}




.submit-act-popup__btn {
  width: 100%;
  padding: 14px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 15px */
  letter-spacing: -0.9px;
  border-radius: 8px;
  background-color: var(--two-factor-btn-bg-color);
  color: var(--two-factor-btn-text-color);

}

.submit-act-popup__btn_agree {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 15px */
  letter-spacing: -0.9px;
  color: var(--two-factor-btn-text-color);
  text-align: center;
}

.submit-act-popup__btn-text_agree {
  color: var(--two-factor-btn-text-color);
}

.submit-act-popup__btn_disagree {
  background-color: var(--input-select-bg-color);
  color: var(--text-color);
}

.submit-act-popup__btn-text_disagree {
  color: var(--text-color);
}

@media (max-width: 448px) {
  .submit-act-popup__container {
    width: 90%;

  }

  .submit-act-popup__text {
    font-size: 16px;
  }
}
.walk-through {
    width: 100%;
    background-color: var(--header-bg-color);


    display: flex;
    flex-direction: column;
    align-items: center;
}



.walk-through__content {
    max-width: 2000px;
    width: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.walk-through__slider {
    overflow: hidden;
    width: 100%;
    padding-left: 100px;
}

.walk-through__slides {

    position: relative;

    width: 100%;
    height: 100%;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
    z-index: 0 !important;
}

.walk-through__slide {
    width: fit-content !important;
    max-width: 90% !important;
    height: 100%;
    /* max-width: 90%; */

    background-color: var(--contrast-coor);
    /* margin-right: 16px; */
    position: relative;
    /* margin-left: 30px; */
    display: flex !important;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 4px;
    padding: 20px;
    min-width: 275px;
}

.walk-through__slide:last-of-type {
    margin-right: 40px;
}

.walk-through__slide_0 {

    background: #7E31CB;
}

.walk-through__slide_1 {
    background-color: #EB4A4A;
}

.walk-through__slide_2 {
    background-color: #1699B5;
}

.walk-through__slide_done {
    background-color: #02A766;
}

.walk-through__slide_to-do {
    display: grid !important;
    grid-template-columns: 4fr 5fr;
    grid-template-rows: max-content;
    column-gap: 40px;

}

.walk-through__slide-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.walk-through__slide-title {
    color: #FFF;
    opacity: 0.8;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 28px */
    letter-spacing: -0.56px;
    max-width: 220px;
    padding-bottom: 40px;
}

.walk-through__slide-description {
    color: #FFF;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    max-width: 220px;
}

.walk-through__slide-btn {
    padding: 12px 52px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.28px;

    border-radius: 600px;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: fit-content;
    margin: auto 0 0;
}

.walk-through__slide_0 .walk-through__slide-btn {
    color: #7E31CB;
}

.walk-through__slide_1 .walk-through__slide-btn {
    color: #EB4A4A;
}

.walk-through__slide_2 .walk-through__slide-btn {
    color: #1699B5;
}

.walk-through__slide_done .walk-through__slide-btn {
    color: #02A766;
}

.walk-through__slide-btn-done-icon {
    width: 11px;
    height: 12px;
}

.walk-through__slide-btn-done-icon-fill {
    fill: #02A766;
}


@media (max-width: 880px) {
    .walk-through__slider {

        padding-left: 16px;

    }

    .walk-through__slide_to-do {
        grid-template-columns: 3fr 5fr;
        column-gap: 20px;
    }

    .walk-through__slide-description {
        font-size: 11px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
    }
}
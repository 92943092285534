.users-create {
    display: flex;
    flex-direction: column;
}

.users-create_form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 32px;
}

.users-create_form-column{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 100px;
}

@media (max-width: 880px) {
    .users-create_form{
        grid-template-columns: 1fr;
    }
}
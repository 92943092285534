.form-control-btn {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  background-color: var(--input-select-bg-color);
}

.form-control-btn_disabled {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

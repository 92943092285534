.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 136px 0 0;
    height: 100%;
    position: relative;
}

.login__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.login__recovery {
    margin: 20px 0 0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
    color: var(--contrast-color);
}

.login__change-page {
    margin: auto 0 0;
    /* text-align: center; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.72px;
    align-self: flex-start;
    padding-top: 30px;
  
}

.login__change-page-link {
    font-style: normal;
    font-weight: 500;
    color: var(--contrast-color);
}

.login__support {
    max-width: 235px;
    margin: 12px 0 0;
    text-align: center;
    color: var(--contrast-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
}



@media (max-width: 650px) {
    .login {
        margin: 30px 0 0;

    }

}
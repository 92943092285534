.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 20px 0;
    border-radius: 600px;
    box-sizing: border-box;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.72px;
    transition: all 0.2s linear;
    height: 60px;
}

.button_type_contrast-color {
    background-color: var(--contrast-color);
    color: var(--text-on-contrast-color);
}

.button_type_invalid {
    opacity: 0.3;
    cursor: no-drop;
    pointer-events: none;
}

.button_type_loading {
    pointer-events: none;
    opacity: 0.9;
}

@media (max-width: 650px) {
    .button {
        padding: 15px 0;
        height: 50px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 111.111% */
        letter-spacing: -0.72px;
    }
}
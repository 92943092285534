.product-form {
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 16px;
    padding: 16px 16px 100px;
    box-sizing: border-box;
}

.product-form_type_two-columns-pc {
    grid-template-columns: repeat(2, minMax(0, 1fr));
    column-gap: 32px;
}

.product-form__container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 16px;
}

.product-form__price-block {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-template-rows: max-content;
    column-gap: 16px;
}

.product-form__price-block_with-main-amount {
    grid-template-columns: 1fr 100px 100px;
}


.product-form__parameters {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.product-form__parameter {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr)) 24px;
    grid-template-rows: max-content;
    column-gap: 8px;
    align-items: center;
}

@media (max-width: 880px) {
    .product-form {
        padding: 16px 16px 32px;
    }

    .product-form_type_two-columns-pc {
        grid-template-columns: 1fr;
        column-gap: 16px;
    }

    .product-form__price-block {
        grid-template-columns: repeat(2, minMax(0, 1fr));
        column-gap: 8px;
    }

    .product-form__price-block_with-main-amount {
        grid-template-columns: repeat(3, minMax(0, 1fr));
    }

    /* .product-form__parameter{
        grid-template-columns: 1fr;
        grid-template-rows: max-content;
        grid-auto-rows: max-content;
        row-gap: 4px;
    } */
}
.select-checkbox {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-color: var(--controls-bg-color);
    transition: all 0.1s ease-in-out;
}

.select-checkbox__selected {}

.select-checkbox__inverted{
    background-color: var(--secondary-bg-color);
}

.select-checkbox__tick {
    position: absolute;
    width: 10px;
    height: 12px;
    opacity: 0;
    transition: all 0.05s linear;
}

.select-checkbox__tick-fill {
    fill: var(--text-color);
}

.select-checkbox__selected .select-checkbox__tick {
    opacity: 1;
}
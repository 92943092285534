.color-input {
  width: 100%;
}

@media (max-width: 600px) {
  .MuiColorInput-Button {
    width: 100% !important;
    height: 100% !important;
    box-shadow: none !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }
}

.filter-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 12px 10px 12px;
    border-radius: 6px;
    border: 1px #EAEBEE solid;
    transition: all 0.2s ease-in-out;
}

.filter-card_type_edit {
    border-color: #9747FF;
}

.filter-card__heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter-card__name-box{
    display: flex;
    flex-direction: column;
}

.filter-card__name {
    color: #2C314F;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.filter-card__value{
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.filter-card__name-type{
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.filter-card__options{
    width: 100%;
    display: flex;
    flex-direction: column;
   
    gap: 12px;
    margin: 24px 0 0;
    max-height: 300px;
    overflow: scroll;
}

.filter-card__option{
    box-sizing: border-box;
    padding: 10px 12px 10px 12px;
    border-radius: 6px;
    border: 1px #EAEBEE solid;
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}
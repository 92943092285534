.statistics-main {
    display: flex;
    flex-direction: column;
}

.statistics-main__heading {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    align-items: center;
    gap: 24px;
    padding: 0 80px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    min-height: 26px;
}

.statistics-main__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -1.44px;
    color: var(--text-color);
}




.statistics-main__periods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    margin: 0 0 0 auto;
    border-radius: 4px;
    overflow: hidden;
}

.statistics-main__period {
    color: var(--text-color);
    background-color: var(--input-select-bg-color);
    padding: 6px 12px;
    box-sizing: border-box;
    border-right: 1px solid var(--input-select-bg-color);
    transition: all 0.2s ease-in-out;

}

.statistics-main__period:last-of-type {
    border: none;
}


.statistics-main__period_selected {
    background-color: var(--input-select-selected-bg-color);
}

.statistics-main__users-and-promo {
    width: 100%;
    padding: 0 80px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 32px;
    grid-auto-rows: max-content;
    row-gap: 16px;
    margin: 32px 0 0;

}

.statistics-main__graph {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--secondary-bg-color);
    box-sizing: border-box;
    padding: 14px 16px;
    border-radius: 16px;
    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
    gap: 16px;
}

.statistics-main__graph-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -1.44px;
    color: var(--text-color);
}

.statistics-main__graph-two-columns {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    column-gap: 16px;
    row-gap: 16px;
}

.statistics-main__graph-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}

.statistics-main__graph-promos {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 13px;
    height: 100%;
}

.statistics-main__graph-promocode {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: nowrap;
}

.statistics-main__graph-promocode-text {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.statistics-main__graph-promocode-count{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
    white-space: nowrap;
}

.statistics-main__graph-no-promo{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
}

.statistics-main__graph-promocode-all-btn {
    margin-top: auto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
    border-radius: 4px;
    padding: 4px 12px;
    box-sizing: border-box;
    background-color: var(--input-color-bg-color);
    width: fit-content;
}

@media (max-width: 880px) {
    .statistics-main__title {
        font-size: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 120%;
    }

    .statistics-main__heading {
        grid-template-columns: 1fr;
        column-gap: 12px;
        row-gap: 12px;
        padding: 0 16px;
    }

    .statistics-main__periods {
        margin: 0;
    }

    .statistics-main__users-and-promo {
        padding: 0 16px;
    }

    .statistics-main__graph-title {
        font-size: 20px;
    }

    .statistics-main__users-and-promo {
        grid-template-columns: 1fr;
        margin: 16px 0 0;
    }
}
.input__input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 412px;
  box-sizing: border-box;
}


.input__input-box {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  grid-template-rows: max-content;
  column-gap: 16px;
  padding: 16px 20px 16px 32px;
  box-sizing: border-box;
  border: 1px solid var(--input-border-color);
  width: 100%;
  border-radius: 600px;
  transition: all 0.2s linear;
  position: relative;
  z-index: 2;
}



.input__input {

  width: 100%;
  background: transparent;

  border: none;
  height: 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 125% */
  letter-spacing: -0.64px;

  color: var(--text-color);
  box-sizing: border-box;
  margin-top: unset;

  overflow: auto;
  padding: 0;

  transition: all 0.2s linear;
  caret-color: var(--input-border-color);
}


.input__input::placeholder {
  color: var(--input-border-color);
}

.input__input:focus {
  outline: transparent;
  caret-color: var(--text-color);
}



.input__input-box_focused {
  border-color: var(--text-color);
}

.input__input-submit {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  border-radius: 7px;
  background-color: var(--secondary-bg-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 20px */
  letter-spacing: -0.8px;
  cursor: no-drop;
  transition: all 0.2s linear;
  pointer-events: none;
}

.input__input-submit_active {
  background-color: var(--secondary-active-bg-color);
  color: var(--contrast-color);
  cursor: pointer;
  pointer-events: all;
}

.input__input-box_error {
  border-color: var(--error-color) !important;
}

.input__input-box_error .input__input {
  color: var(--error-color) !important;
}

.input__input-box_error .input__input-submit {
  opacity: 0.3;
}

.input__show-btn {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.input__show-btn-icon {
  width: 18px;
  height: 18px;
}

.input__show-btn-icon-fill {
  fill: var(--text-color);
}


.input__input-box_error {
  border-color: var(--error-color);
}

@media (max-width: 650px) {
  .input__input-box {
    padding: 13px 20px 13px 32px;
  }

  .input__input {
    height: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.48px;
  }
  .input__show-btn{
    width: 16px;
    height: 16px;
  }
  .input__show-btn-icon{
    width: 16px;
    height: 16px;
  }
}
.category-block {
  width: 332px;
  height: 75vh;
  border-right: 1px solid var(--input-select-bg-color);
  box-sizing: border-box;
  padding: 0 16px;
  overflow: scroll;
}

.category-block__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  /* overflow: scroll; */
}

.category-block__form {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--category-bg-color);
  box-sizing: border-box;
  padding: 0 16px;
}

.category-block__input {
  width: 100%;
  color: var(--text-color);
  font-family: Golos Text;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  outline: transparent;
  border: none;
  padding: 0;
}

.category-block__submit-btn {
  height: 100%;
  max-height: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  border-radius: 4px;
  color: var(--text-color);
  background: var(--category-bg-color);
  box-sizing: border-box;
  padding: 4px 12px;
  transition: all 0.15s ease-in-out;
}

.category-block__submit-btn_disabled {
  opacity: 0.5;
}

.category-block__add-btn {
  width: 100%;
  height: 44px;
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  justify-items: start;
  gap: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  border-radius: 8px;
  background: var(--category-btn-color);
  color: var(--text-color);
  box-sizing: border-box;
  padding: 14px 16px;
}

.category-block__add-icon {
  width: 16px;
  height: 16px;
}

.category-block__icon-fill {
  fill: var(--text-color);
  transition: all 0.1s ease-in-out;
}

.category-block__more-btn {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background: var(--category-btn-color);
  color: var(--text-color);
  box-sizing: border-box;
  padding: 14px 16px 14px 42px;
}

.category-block__more-btn_disabled {
  pointer-events: none;
  user-select: none;
}

.category-block__more-btn-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  transition: all 0.1s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color);
  opacity: 0;
  visibility: hidden;
}

.category-block__more-btn-text_visible {
  opacity: 1;
  visibility: visible;
}

.category-block__btn-preloader {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
}

.category-block__btn-preloader_visible {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 750px) {
  .category-block {
    width: 100%;
    border-right: none;
  }
}

.auth-from-inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 34px 30px 40px 20px;
    box-sizing: border-box;
}

.auth-from-inside__heading {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.auth-from-inside__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 100% */
    letter-spacing: -1.92px;
}

.auth-from-inside__subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.72px;
}


@media (max-width: 650px) {
 .auth-from-inside{
    padding: 30px 32px 40px;
 }

}
.payment-systems {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.payment-systems__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.payment-systems__btn {
    width: 100%;
    padding: 14px 16px;
    box-sizing: border-box;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    border-radius: 8px;
    background-color: var(--input-select-bg-color);


}

.payment-systems__hint {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.payment-systems__vat-code{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.platform {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: var(--platform-bg-color);
}

.platform__content {
    max-width: 1440px;
    width: 100%;

    margin: 24px 0 0;
    padding-bottom: 20px;
}

.platform__content_home {
    margin: 0;
    max-width: unset;
}

@media (max-width: 880px) {
    .platform__content {
        margin: 16px 0 0;
    }

    .platform__content_home {
        margin: 0;
    }
}
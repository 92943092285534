.delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.delivery__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.delivery__selectors {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.delivery__selector {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    letter-spacing: -0.48px;
    padding: 0 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 6px;
    color: var(--text-color);
    white-space: nowrap;
    border: 1px solid var(--text-color);
}

.delivery__selector_active {
    background-color: var(--contrast-color);
    color: var(--bg-color);
    border-color: var(--contrast-color);
}

@media (max-width: 880px) {
    .delivery__selectors {
        gap: 4px;
    }

    .delivery__selector {
        padding: 0 8px;
        font-size: 12px;
    }
}
.import-file {
    display: flex;
    flex-direction: column;
}

.import-file__item-from-container{
    padding: 0;
}

.import-file__two-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 75vh;
}

.import-file__products-list{
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--table-separator-color);
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
}
.import-file__products-form{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
}

.import-file__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
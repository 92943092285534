.change-payment-method-popup {
    display: flex;
    flex-direction: column;
}

.change-payment-method-popup__heading {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr max-content;
    column-gap: 12px;
    grid-template-rows: max-content;
}

.change-payment-method-popup__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -0.96px;
}

.change-payment-method-popup__cards {
    margin: 20px 0 0;
}

.change-payment-method-popup__btn {
    margin: 36px 0 0;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 16px */
    letter-spacing: -0.32px;
    color: var(--text-on-contrast-color);
    background-color: var(--violet-light-bg);
    padding: 18px 0;
    box-sizing: border-box;
    border-radius: 8px;
}

@media (max-width: 880px) {
    .change-payment-method-popup {
        padding: 0 16px;
    }
}

.shop-preloader {
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    /* Instead of display: none */
    opacity: 0;
    position: fixed;
    background-color: var(--header-bg-color);
    z-index: 1000;
    top: 0;
    display: flex;
    /* Make it always flex but hidden */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: visibility 0s, opacity 0.5s linear;
    /* Transition for smooth in and out */
}

.shop-preloader_visible {
    visibility: visible;
    /* Instead of changing display, we change visibility */
    opacity: 1;
}

.shop-preloader__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: -0.32px;
    color: var(--header-text-color);
    margin: 24px 0 0;
}

.shop-preloader__img {
    width: 300px;
    margin-bottom: 40px;
}
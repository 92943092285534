.products-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 0 0;
  box-sizing: border-box;

  height: 100%;
}

.products-list__heading {
  width: 100%;
  padding: 0 16px 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 16px 1fr 80px;
  grid-template-rows: max-content;
  column-gap: 16px;
  align-items: center;
  border-bottom: 1px solid var(--table-separator-color);
}

.products-list__heading_type_no-selectable {
  grid-template-columns: 1fr 80px;
}

.products-list__heading-checkbox {
  display: flex;
  opacity: 1;
  visibility: visible;
  transition: all 0.15s ease-in-out;
}

.products-list__heading-checkbox_hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}

.products-list__heading-title {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 11px */
  letter-spacing: -0.66px;
  color: var(--table-title-color);
}

.products-list__items {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

@media (max-width: 880px) {
  .products-list {
    height: calc(100vh - 67px - 16px - 26px - 16px - 16px);
    background-color: var(--secondary-bg-color);
  }
}

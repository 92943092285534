.edit-filter {
    width: 100%;
}


.edit-filter__icon {
    width: 16px;
    height: 16px;
}


.edit-filter__icon-fill {
    fill: var(--text-color);
}

.edit-filter__icon-stroke {
    stroke: var(--text-color);
}
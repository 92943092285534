.edit-category {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.edit-category__form {
  width: 100%;
  min-height: 287px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.edit-category__form-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 880px) {
  .edit-category__form {
    min-height: unset;
    grid-template-columns: 1fr;
  }
}

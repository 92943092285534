.pages-and-files {
    display: flex;
    flex-direction: column;
}

.pages-and-files__item-from-container {
    padding: 0;
}

.pages-and-files__two-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 75vh;
}

.pages-and-files__products-list {
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--table-separator-color);
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
}

.pages-and-files__products-form {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
}

.pages-and-files__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

@media (max-width: 880px) {
    .pages-and-files__two-columns {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}
.orders-list{
    display: flex;
    flex-direction: column;
}

.orders-list__table{
    margin: 16px auto 0;
    width: 100%;
    max-width: 1440px;
    padding: 0 0 0;
    box-sizing: border-box;
}

.orders-list__preloader {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.orders-list__more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.2s linear;
    background-color: var(--controls-bg-color);
    margin: 0 auto;
  }
  
  .orders-list__more-btn_disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
  }
  
  .orders-list__more-btn-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.72px;
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);
    opacity: 0;
    visibility: hidden;
  }
  
  .orders-list__more-btn-text_visible {
    opacity: 1;
    visibility: visible;
  }
  
  .orders-list__btn-preloader {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
  }
  
  .orders-list__btn-preloader_visible {
    opacity: 1;
    visibility: visible;
  }

@media (max-width: 880px) {
    .orders-list__table{
        padding: 0 0 0 0;
    }
}
.finance-info-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-right: 1px solid var(--finance-separator-color);
    width: 100%;
    box-sizing: border-box;
    padding: 8px 20px;
}

.finance-info-box:last-of-type {
    border-right: none;
}

.finance-info-box__heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.finance-info-box__heading-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.24px;
    white-space: nowrap;

}

.finance-info-box__heading-btn {
    background-color: var(--input-select-bg-color);
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    width: fit-content;

}

.finance-info-box__main-value {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 116.667% */
    letter-spacing: -0.96px;
}

.finance-info-box__values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    align-items: center;
}

.finance-info-box__sub-value-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: auto 0 0;
    /* 233.333% */
    letter-spacing: -0.24px;
}

.finance-info-box__sub-value-card-icon {
    width: 24px;
    height: 16px;
}

@media (max-width: 880px) {
    .finance-info-box {
        border-right: unset;
    }

    .finance-info-box:last-of-type {
        border-top: unset;
        border-top: 1px solid var(--finance-separator-color);
    }
}
.notifications-telegram {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.notifications-telegram__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}


.notifications-telegram__hint {

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 18px */
    letter-spacing: -0.9px;
    padding-bottom: 20px;
}

.notifications-telegram__hint_contrast{
    font-weight: 500;
    color: var(--contrast-color);
}

.notifications-telegram__hint-link{
    color: var(--contrast-color);
    text-decoration: underline;
}
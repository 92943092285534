.product-page {
    display: flex;
    flex-direction: column;
}

.product-page__item-from-container {
    padding: 0;
}

.product-page__two-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 75vh;
}

.product-page__column {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    overflow: scroll;
    padding: 16px;
}

.product-page__column:first-of-type {
    border-right: 1px solid var(--table-separator-color);
}


@media (max-width: 880px) {

    .product-page__two-columns {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content;
        grid-auto-rows: max-content;
        row-gap: 16px;
    }

    .product-page__column:first-of-type {
        border-right: none;
    }
}
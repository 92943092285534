.home-page-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 200px;
}

.home-page-main__content {
    width: 100%;
    max-width: 1600px;
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    box-sizing: border-box;
}

.home-page-main__heading {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-areas: 'SHOP SHOP BALANCE';
    grid-template-rows: max-content;
    column-gap: 20px;

}

.home-page-main__shop {
    grid-area: SHOP;
    display: grid;
    grid-template-columns: 84px 1fr;
    grid-template-rows: max-content;
    align-items: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    column-gap: 16px;
}

.home-page-main__shop-icon {
    width: 84px !important;
    height: 84px !important;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--avatar-placeholder-bg-color);
}

.home-page-main__shop-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* justify-content: center; */

}

.home-page-main__shop-name {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 113%;
    /* 113.333% */
    letter-spacing: -1.2px;
    color: var(--text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.home-page-main__shop-additional-infos {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.home-page-main__shop-id-and-role {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 111%;

    letter-spacing: -0.36px;
    opacity: 0.7;
}

.home-page-main__shop-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    white-space: nowrap;

}

.home-page-main__shop-link-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    letter-spacing: -0.24px;
    padding: 3px 10px;
    border-radius: 4px;
    background-color: var(--contrast-color);
    color: var(--text-on-contrast-color);
    white-space: nowrap;
}

.home-page-main__shop-link-icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--contrast-color);
    transition: all 0.2s ease-in-out;
}

.home-page-main__shop-link-icon-box_copied {
    background-color: var(--text-on-contrast-color);
}

.home-page-main__shop-link-icon {
    width: 12px;
    height: 12px;
}

.home-page-main__shop-link-icon-stroke {
    transition: all 0.2s ease-in-out;
    stroke: var(--text-on-contrast-color);
}

.home-page-main__shop-link-icon-box_copied .home-page-main__shop-link-icon-stroke {
    stroke: var(--contrast-color);
}

.home-page-main__info-contaienr {
    display: flex;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding: 12px 12px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--secondary-bg-color);

    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
    box-sizing: border-box;
}

.home-page-main__balance-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
}

.home-page-main__balance-value {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 31.2px */
    letter-spacing: -1.44px;
    color: var(--text-color);
}

.home-page-main__infos {
    width: 100%;
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 20px;
    row-gap: 20px;
}

.home-page-main__info-heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.home-page-main__info-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 31.2px */
    letter-spacing: -1.44px;
    color: var(--text-color);
}

.home-page-main__info-title_stats {
    color: #03AB00;
}

.home-page-main__info-title_orders {
    color: #007BD4;
}

.home-page-main__info-title_products {
    color: #8E1191;
}


.home-page-main__info-link {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
    padding: 4px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--input-select-bg-color);
}

.home-page-main__online {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
}

.home-page-main__online-count {
    color: #03AB00;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 48px */
    letter-spacing: -2.88px;
}

.home-page-main__online-title {
    color: #03AB00;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.home-page-main__online-per-days {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-page-main__online-per-days-iten {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
    box-sizing: border-box;
    border-bottom: 1px solid var(--table-separator-color);
}

.home-page-main__online-per-days-main-title {
    margin: 12px 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
}

.home-page-main__online-per-days-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
}

.home-page-main__online-per-days-value {
    text-align: right;
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.home-page-main__infos-column {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    column-gap: 20px;
    row-gap: 20px;
}

.home-page-main__info-parameter {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 48px */
    letter-spacing: -2.88px;
    color: #007BD4;
}

.home-page-main__info-hint {
    margin: 20px 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    color: var(--text-color);
}

.home-page-main__info-top-sales {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}

.home-page-main__info-top-sale {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    column-gap: 12px;
    align-items: center;
}


.home-page-main__info-top-sale-img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
}

.home-page-main__info-top-sale-img-placeholder {
    background-color: var(--input-select-bg-color);
}

.home-page-main__info-top-sale-texts{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.home-page-main__info-top-sale-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    transition: all 0.1s ease-in-out;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (max-width: 1100px) {
    .home-page-main__infos {
        grid-template-columns: repeat(2, minMax(0, 1fr));

    }
}

@media (max-width: 920px) {
    .home-page-main__heading {
        grid-template-columns: repeat(2, minMax(0, 1fr));
        grid-template-areas: 'SHOP BALANCE';
    }
}

@media (max-width: 880px) {
    .home-page-main__content {
        padding: 0 16px;
        margin: 20px 0 0;
    }

    .home-page-main__heading {
        grid-template-columns: 1fr;
        grid-template-areas: 'SHOP';
        /* 'BALANCE'; */
        row-gap: 20px;
    }

    .home-page-main__infos {
        grid-template-columns: 1fr;

    }



    .home-page-main__info-parameter {
        font-size: 28px;
    }

    .home-page-main__info-title {
        font-size: 18px;
    }

    .home-page-main__online-count {
        font-size: 28px;
    }

    .home-page-main__online-title {
        font-size: 10px;
    }

    .home-page-main__shop {
        align-items: unset;
        grid-template-columns: 54px 1fr;
        column-gap: 12px;
    }

    .home-page-main__shop-icon {
        width: 54px !important;
        height: 54px !important;
    }

    .home-page-main__shop-name {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 24px */
        letter-spacing: -0.96px;
        white-space: pre-line;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .home-page-main__shop-additional-infos {
        gap: 10px;
        justify-content: space-between;
    }

    .home-page-main__shop-id-and-role {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -0.32px;
    }

}
.statistics-sells {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 80px;
    margin: 16px 0 0;
}

.statistics-sells__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--secondary-bg-color);
    box-sizing: border-box;
    padding: 14px 16px;
    border-radius: 16px;
    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
    gap: 16px;
}

.statistics-sells__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -1.44px;
    color: var(--text-color);
}

.statistics-sells__graphs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-areas: 'FIRST SECOND THIRD';
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    column-gap: 34px;
    row-gap: 34px;

}

.statistics-sells__graph {
    width: 100%;
    height: 100%;
}

.statistics-sells__graph_1 {
    grid-area: FIRST;
}

.statistics-sells__graph_2 {
    grid-area: SECOND;
}

.statistics-sells__graph_3 {
    grid-area: THIRD;
}

@media (max-width: 880px) {

    .statistics-sells {
        grid-template-columns: 1fr max-content;
        column-gap: 12px;
        padding: 0 16px;
    }

    .statistics-sells__graphs {
        grid-template-columns: repeat(2, minMax(0, 1fr));
        grid-template-areas:
            'FIRST SECOND'
            'THIRD THIRD';
    }

    .statistics-sells__title {
        font-size: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 120%;
    }

}

@media (max-width: 600px) {
    .statistics-sells__graphs {
        grid-template-columns: minMax(0, 1fr);
        grid-template-areas:
            'FIRST'
            'SECOND'
            'THIRD';
    }
}
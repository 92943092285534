.address-map {
    width: 100%;
    height: 300px;
    position: relative;
}

.address-map__preloader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: repeating-linear-gradient(to right, var(--platform-bg-color) 0%, var(--bg-color) 50%, var(--platform-bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient_preloader 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.address-map__box {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.address-map__hint {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.address-map__hint-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--input-border-color);
    gap: 4px;
}

.address-map__hint-zone-title {
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 120% */
    text-transform: uppercase;
}

.address-map__hint-zone-number {
    padding: 3px 0;
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
    color: var(--bg-color);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
}

.address-map__hint-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.address-map__hint-info-title {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    opacity: 0.7;
}

.address-map__hint-info-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

@keyframes gradient_preloader {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}
.personal-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.personal-info__input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */

    width: 100%;
    box-sizing: border-box;
    gap: 8px;
}

.personal-info__input-heading {
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.personal-info__input-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 91.667% */
    letter-spacing: -0.72px;
    color: var(--input-label-color);
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
}

.personal-info__input-label__blue {
    color: var(--input-label-secondary-color)
}

.personal-info__input-label__error {
    color: var(--error-color);
}

.personal-info__input-box {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 9px;
    padding: 10px 16px;
    box-sizing: border-box;
    border: 1px solid var(--input-border-color);
    width: 100%;
    border-radius: 8px;
    transition: border-color 0.2s linear;
    position: relative;
    z-index: 2;
    min-height: 47px;
}

.personal-info__input-box_left-btn {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 16px;
}

.personal-info__input-prev-email-value {
    padding: 4px 8px 4px 12px;
    box-sizing: border-box;
    gap: 4px;
    background-color: var(--input-select-bg-color);
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
    position: relative;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.personal-info__input-btn {
    background-color: var(--input-select-bg-color);
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
    padding: 6px 12px;
}

.personal-info__input {
    width: 100%;
    background: transparent;

    border: none;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 111.111% */
    letter-spacing: -0.9px;

    color: var(--text-color);
    box-sizing: border-box;
    margin-top: unset;

    overflow: auto;
    padding: 0;

    transition: color 0.1s linear;
    caret-color: var(--input-border-color);
}

.platform-input__input_type_text-area {
    resize: none;
    overflow: auto;
    width: 100%;
}

.personal-info__input::placeholder {
    color: var(--input-border-color);
}

.personal-info__input:focus {
    outline: transparent;
    caret-color: var(--text-color);
}

.personal-info__btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 9px;
    padding: 10px 16px;
    box-sizing: border-box;
    background-color: var(--input-select-bg-color);
    width: 100%;
    border-radius: 8px;
    transition: all 0.2s linear;
    position: relative;
    z-index: 2;
    min-height: 47px;
    color: var(--text-color);

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
}

.personal-info__input-btn_inactive {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
    cursor: no-drop;
}

.personal-info__btn_logout {
    margin: auto 0 0;
    text-align: center;
    justify-content: center;
    color: var(--two-factor-btn-text-color);
    background-color: var(--two-factor-btn-bg-color);
}
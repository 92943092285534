.action-popup {
    display: flex;
    flex-direction: column;
    /* gap: 12px; */
    width: 100%;
    padding: 0 8px 0;
    box-sizing: border-box;
}

.action-popup__heading {
    display: grid;
    grid-template-columns: minMax(0, 1fr) 24px;
    grid-template-rows: max-content;
    column-gap: 8px;
    width: 100%;
    max-width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
}

.action-popup__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -1.44px;
}

.action-popup__actions {
    margin: 16px 0 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.action-popup__action {
    padding: 12px 9px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 228.571% */
    letter-spacing: -0.56px;
    transition: all 0.2s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--btns-bg-color);
    color: var(--text-color);
    display: flex;
    text-align: left;
}

.action-popup__action_type_main {
    background-color: var(--contrast-color);
    color: var(--text-on-contrast-color);
}


.action-popup__action_inactive {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}


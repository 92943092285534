.block-popup {
    padding: calc(30px - 16px) calc(40px - 16px) calc(40px - 16px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-popup__icon {
    width: 308px;
    height: 200px;
}

.block-popup__title {
    color: var(--text-color);
    margin: 24px 0 0;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
}

.block-popup__text {
    margin: 8px 0 0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.block-popup__btn {
    padding: 14px 18px;
    box-sizing: border-box;
    margin: 24px 0 0;
    background-color: var(--contrast-color);
    color: var(--text-on-contrast-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

@media (max-width: 800px) {
    .block-popup {
        padding: 0;
    }
}
.edit-main-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;

}

.edit-main-page__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    border: 1px solid var(--table-separator-color);
    padding: 8px;
    box-sizing: border-box;
    border-radius: 12px;
}

.edit-main-page__item-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}
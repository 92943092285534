.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;

}

.list__empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.list__create-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.list__create-btn {
    width: fit-content;
    padding: 0 24px;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

.list__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.list__list-item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-template-rows: max-content;
    column-gap: 8px;
    align-items: center;
}


.list__list-item-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: var(--input-select-bg-color);
    color: var(--text-color);
    text-align: start;
    padding: 12px 16px;
    box-sizing: border-box;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border-radius: 8px;
}

.list__list-item_invisible .list__list-item-btn{
    opacity: 0.5;
}


.list__list-item-controls {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 4px;
}

/* .list__list-item-controls_first {
    padding: 20px 0 0;
    box-sizing: border-box;
} */

.list__list-item-control {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--input-select-bg-color);

}

.list__list-item-control_inactive{
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}


.list__list-item-control-icon {
    width: 100%;
    height: 100%;
}

.list__list-item-control-icon-fill {
    fill: var(--text-color);
}

.list__list-item-control-icon_up{
    transform: rotate(180deg);
}
.graph-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.graph-container__title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
}

.graph-container__statistics-infos {
    display: flex;
    flex-direction: column;
    gap: 16px;
}


@media (max-width: 880px) {
 
}
.notification-line {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-line_red {
  background: var(--error-color);
  z-index: 2;
}

.notification-line_green {
  background: var(--ion-green);
  z-index: 1;
}

.notification-line_orange{
  background-color: #F90;
  z-index: 1;
}

.notification-line_walk-through {
  background-color: var(--header-bg-color);
}

.notification-line__text-box {
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-self: center;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.notification-line__text {
  color: var(--text-on-contrast-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.64px;
}

.notification-line__go-back {
  color: var(--header-text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.notification-line__go-back-icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--second-level-header);
  border-radius: 50%;
}

.notification-line__go-back-icon {
  width: 24px;
  height: 24px;
}

.notification-line__go-back-icon-stroke {
  stroke: var(--header-text-color);
}

.notification-line__button {
  border-radius: 600px;
  background: var(--secondary-bg-color);
  padding: 8px 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 15px */
  letter-spacing: -0.9px;
  border-radius: 8px;
  white-space: nowrap;
}

.notification-line__button_red {
  color: var(--error-color);
  z-index: 2;
}

.notification-line__button_green {
  color: var(--ion-green);
  z-index: 1;
}

.notification-line__button_orange{
  z-index: 1;
  color: #F90;
}

@media (max-width: 1100px) {
  .notification-line {
    padding: 12px 16px;
  }

  .notification-line__text-box {
    text-align: left;
    padding: 0;
  }

  .notification-line__text {
    font-size: 12px;
  }

  .notification-line__button {
    padding: 8px 16px;
    font-size: 12px;
  }
}

@media (max-width: 580px) {
.notification-line__text-box{
  flex-wrap: wrap;
}
}


@media (max-width: 480px) {
  .notification-line__text {
    font-size: 10px;
  }

  .notification-line__button {
    font-size: 10px;
  }
}
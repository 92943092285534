.import-main {
    display: flex;
    flex-direction: column;
}

.import-main__file-drop-area {
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.import-main__file-input-label {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
}

.import-main__file-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    z-index: 10;
}

.import-main__hint-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    max-width: 290px;
}

.import-main__loader-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 290px;
    gap: 16px;
}

.import-main__hint-text {
    text-align: center;
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 14.3px */
    letter-spacing: -0.78px;

}

.import-main__loader-text {
    text-align: center;
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 14.3px */
    letter-spacing: -0.78px;
}

.import-main__loader-text_file-name{
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.import-main__hint-icon {
    width: 100px;
    height: 104px;
}

.import-main__hint-icon-fill {
    fill: var(--text-color);
}

.import-main__loader {
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.import-main__loader-logo-number-icon {
    width: 19px;
    height: 66px;
}

.import-main__loader-logo-number-icon-fill {
    fill: var(--text-color);
}


.import-main__loader-logo-circle-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    animation: rotate 2s infinite linear;
}

.import-main__loader-logo-circle-icon-fill {
    fill: var(--text-color);
}

.import-main__control{
    padding: 5px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    transition: all 0.2s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--controls-bg-color);
    color: var(--text-color);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}
.kit-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.kit-card__container {
  padding: 0;
  height: 75vh;
}

.kit-card__form {
  height: 75vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  overflow: scroll;
}

.kit-card__constructor-block {
  gap: 8px;
  padding: 0;
}

.kit-card__block-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--template-kit-card-column), 1fr) 80px;
  align-items: end;
  gap: 8px;
}

.kit-card__block-container_type_wide {
  grid-template-columns: 1fr;
}

.kit-card__example-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
}

@media (max-width: 1050px) {
  .kit-card__form {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content, max-content) 1px minmax(
        min-content,
        max-content
      );
  }

  .kit-card__example-block {
    gap: 32px;
  }
}

@media (max-width: 470px) {
  .kit-card__block-container_type_column {
    grid-auto-flow: column;
    grid-template-columns: 1fr 80px;
    grid-template-rows: repeat(2, 1fr);
  }

  .kit-card__block-container_type_column .kit-card__control-btns {
    grid-row: 1/-1;
    box-sizing: border-box;
    padding-bottom: 37px;
  }
}

.subscribe-cards__box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    box-sizing: border-box;
    gap: 40px;
}

.subscribe-card__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.subscribe-card {
    border-radius: 30px;
    background: var(--secondary-bg-color);
    box-shadow: 0px 13px 26px 0px rgba(150, 152, 171, 0.23);
    padding: 22px 22px 40px 22px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
 box-sizing: border-box;
    transition: all 0.2s ease-in-out;
}

.subscribe-card_choose {
    background-color: var(--contrast-color);
    transition: all 0.2s ease-in-out;
}

.subscribe-card__icon-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 64px;
}

.subscribe-card__text {
    font-family: Golos Text;
    font-style: normal;
    transition: all 0.2s ease-in-out;
}

.subscribe-card_choose .subscribe-card__text {
    color: var(--secondary-bg-color);
    transition: all 0.2s ease-in-out;
}

.subscribe-card__number {
    color: var(--contrast-color);
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
    letter-spacing: -0.48px;
    transition: all 0.2s ease-in-out;
}

.subscribe-card__number_choose {
    color: var(--secondary-bg-color);
    transition: all 0.2s ease-in-out;
}

.subscribe-card__title {
    color: var(--contrast-color);
    font-size: 32px;
    font-weight: 500;
    line-height: 90%;
    /* 28.8px */
    letter-spacing: -1.92px;
    min-height: 117px;
}

.subscribe-card__price {
    color: var(--text-color);
    font-size: 32px;
    font-weight: 400;
    line-height: 90%;
    /* 28.8px */
    letter-spacing: -1.92px;
}

.subscribe-card__desc {
    color: var(--text-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    /* 110% */
    letter-spacing: -1.2px;
}

.subscribe-card__button {
    padding: 20px 40px;
    border-radius: 600px;
    background: var(--contrast-color);
    color: var(--secondary-bg-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.72px;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.subscribe-card__button_visible {
    visibility: initial;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.subscribe-card__button_unvisible {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.subscribe-card_choose .subscribe-card__button {
    background: var(--secondary-bg-color);
    color: var(--contrast-color);
    transition: all 0.2s ease-in-out;
}

.subscribe-card__button_choose {
    background: var(--contrast-color);
    margin-top: 20px;
    text-align: center;
    width: 100%;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.subscribe-card__icon {
    stroke: var(--contrast-color);
    transition: all 0.2s ease-in-out;
}

.subscribe-card__icon_selected{
    stroke: var(--secondary-bg-color);
}

.subscribe-card__title_laptop {
    display: block;
}

.subscribe-card__title_mobile {
    display: none;
}

.subscribe-card__size {
    display: block;
}

.subscribe-card__size_mobile {
    display: none;
}

@media (max-width:1200px) {
    .subscribe-cards__box {
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .subscribe-card {
        gap: 32px;
        max-width: 100%;
        padding: 22px;
        box-sizing: border-box;
    }

    .subscribe-card__title {
        min-height: unset;
    }

    .subscribe-card__title_laptop {
        display: none;
    }

    .subscribe-card__title_mobile {
        display: block;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 90%;
        /* 21.6px */
        letter-spacing: -1.44px;
    }

    .subscribe-card__price {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
        /* 21.6px */
        letter-spacing: -1.44px;
    }

    .subscribe-card__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */
        letter-spacing: -0.96px;
    }

    .subscribe-card__icon-box {
        display: grid;
        grid-template-columns: 48px 1fr 13px;
        gap: 10px;
    }


    .subscribe-card__size {
        display: none;
    }

    .subscribe-card__size_mobile {
        display: block;
    }
}

@media (max-width:960px) {
    .subscribe-cards__box {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        margin-top: 16px;
    }

    .subscribe-card__button {
        max-height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .subscribe-card__button_choose {
        position: fixed;
        bottom: 16px;
        width: calc(100% - (14px * 2));
        box-sizing: border-box;
        max-height: unset;
    }

    .subscribe-card {
        padding: 16px 16px 22px 16px;
        border-radius: 24px;
        max-width: unset;
        box-sizing: border-box;
        gap: 12px;
    }
}
.tokens {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tokens__container {
  padding: 0;
}

.tokens__form {
  height: 75vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: minmax(min-content, max-content) 1px minmax(min-content,
      max-content);
  grid-auto-rows: 1px minmax(min-content, max-content);
  overflow: scroll;
}

.tokens__fonts-block {
  grid-template-columns: 80px 1fr 80px;
}

.tokens__colors-block {
  grid-template-columns: repeat(4, 1fr);
}

.tokens__colors-block_type_single {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1250px) {
  .tokens__form {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .tokens__fonts-block {
    grid-template-columns: 55px 1fr 80px;
  }

  .tokens__colors-block {
    grid-template-columns: 55px repeat(3, 1fr);
  }

  .tokens__colors-block_type_single {
    grid-template-columns: repeat(2, 1fr);
  }
}
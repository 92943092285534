.finance {
    display: flex;
    flex-direction: column;
}

.finance__item-from-container {
    padding: 0;
    width: 100%;
    height: 75vh;
    box-sizing: border-box;
    padding: 16px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.finance__info{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.no-shop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.no-shop__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 360px;
    box-sizing: border-box;
    padding: 24px 20px;
    background-color: var(--secondary-bg-color);
    border-radius: 16px;

    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
}

.no-shop__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 120% */
    letter-spacing: -0.4px;
}

.no-shop__availible-shops {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 500px;
    overflow: scroll;
}


.no-shop__shop-btn {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 64px 1fr;
    align-items: center;
    grid-template-rows: max-content;
    column-gap: 8px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
}

.no-shop__shop-btn:hover {
    background-color: var(--drop-hover-bg-color);
}

.no-shop__shop-btn-icon {
    width: 64px !important;
    height: 64px !important;
}

.no-shop__shop-btn-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
}

.no-shop__shop-btn-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 110% */
    letter-spacing: -0.4px;
    white-space: nowrap;
    text-align: left;
    max-width: 100%;
    overflow: hidden;
}

.no-shop__shop-btn-id-and-role {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 120% */
    letter-spacing: -0.2px;
    opacity: 0.7;
    text-align: left;
}

.no-shop__shop-btn-selected-icon {
    width: 14px;
    height: 14px;
}

.no-shop__shop-btn-selected-icon-stroke {
    stroke: var(--text-color);
}

.no-shop__create {
    padding: 19px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--contrast-color);
    color: var(--secondary-bg-color);
    border-radius: 12px;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.36px;
}
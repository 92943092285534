.filter-edit-form{
    margin: 12px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.filter-edit-form__toggle{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter-edit-form__toggle-title{
    padding: 0 0 0 16px;
    box-sizing: border-box;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    /* 91.667% */
    letter-spacing: -0.72px;
    color: var(--input-label-color);
    white-space: nowrap;
}

.filter-edit-form__convert{

    padding: 8px 9px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 228.571% */
    letter-spacing: -0.56px;
    transition: all 0.2s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--btns-bg-color);
    color: var(--text-color);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media (max-width: 880px) {
    .filter-edit-form__toggle-title{
        padding: 0;
        font-size: 12px;
    }
}
.address-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}

.address-input__hints {
    z-index: 2;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
    background-color: var(--input-dropdown-bg-color);
    padding: 8px 8px;
    border-radius: 8px;
    box-sizing: border-box;
}

.address-input__hint {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.48px;
    padding: 8px 8px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    color: var(--text-color);
    text-align: left;
    border-radius: 4px;
    width: 100%;
}

.address-input__hint_selected {
    background-color: var(--input-select-selected-bg-color);
    /* border: 1px solid var(--text-color); */
}
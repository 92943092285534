.category-btn {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 8px;
  background: var(--category-bg-color);
  box-sizing: border-box;
  padding: 0 16px;
  transition: all 0.1s ease-in-out;
}

.category-btn_selected {
  background: var(--category-bg-active-color);
  pointer-events: none;
  user-select: none;
}

.category-btn_disabled {
  pointer-events: none;
  user-select: none;
}

.category-btn__title {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.1s ease-in-out;
}

.category-btn_selected .category-btn__title {
  color: var(--text-on-contrast-color);
}

.category-btn__info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-btn__edit-link {
  width: 16px;
  height: 16px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: none;
  transition: all 0.1s ease-in-out;
}

.category-btn_selected .category-btn__edit-link {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  cursor: pointer;
}

.category-btn__label {
  display: flex;
  align-items: center;
  gap: 3px;
}

.category-btn__label-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  transition: all 0.1s ease-in-out;
}

.category-btn_selected .category-btn__label-text {
  color: var(--text-on-contrast-color);
}

.category-btn__add-btn {
  width: 100%;
  height: 44px;
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  justify-items: start;
  gap: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  border-radius: 8px;
  background: var(--category-btn-color);
  color: var(--text-color);
  box-sizing: border-box;
  padding: 14px 16px;
}

.category-btn__edit-icon {
  width: 16px;
  height: 16px;
}

.category-btn__label-icon {
  margin-top: 3px;
}

.category-btn__add-icon {
  width: 16px;
  height: 16px;
}

.category-btn__icon-fill {
  fill: var(--text-color);
  transition: all 0.1s ease-in-out;
}

.category-btn_selected .category-btn__icon-fill {
  fill: var(--text-on-contrast-color);
}

@media (max-width: 750px) {
  .category-btn_selected {
    background: var(--category-bg-color);
    pointer-events: auto;
    cursor: pointer;
  }

  .category-btn_selected .category-btn__title {
    color: var(--text-color);
  }

  .category-btn__edit-link {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    cursor: pointer;
  }

  .category-btn_selected .category-btn__label-text {
    color: var(--text-color);
  }

  .category-btn_selected .category-btn__icon-fill {
    fill: var(--text-color);
  }
}

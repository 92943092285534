.toggle {
    width: 40px;
    height: 22px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 2px;
    background-color: #BFC5D8;
    transition: all 0.2s ease-in-out;
    position: relative;
    border-radius: 600px;
}

.toggle_active {
    background-color: #1053FF;
}

.toggle__inside {
    border-radius: 50%;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}
.toggle_active .toggle__inside{
    transform: translateX(100%);
}
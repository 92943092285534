.users-main{
    display: flex;
    flex-direction: column;
}

.users-main__table{
    
    margin: 16px auto 0;
    width: 100%;
    max-width: 1440px;
    /* padding: 0 80px; */
    box-sizing: border-box;
}

@media (max-width: 880px) {
    .users-main__table{
        /* padding: 0 0 0 8px; */
    }
}
.desktop-popup__container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    visibility: hidden;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.desktop-popup__container_active {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.desktop-popup {
    max-width: 400px;
    width: 90%;

    background: var(--secondary-bg-color);
    z-index: 1001;
    position: absolute;
    opacity: 0;
    /* padding-bottom: 70px;
      transform: translateY(70px); */
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s linear;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
}

.desktop-popup_active{
    opacity: 1;
}




.desktop-popup__bg {
    transition: all 0.2s linear;

    background: var(--popup-bg-color);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    left: 0;
    top: 0;
}


.desktop-popup__container_active .desktop-popup__bg {
    opacity: 0.4;
}

.desktop-popup__container_active .desktop-popup__bg_darker{
    opacity: 1;
    background: var(--popup-bg-color-darker);
}





.submit-act-popup__container {}

.submit-act-popup__container_active {
    opacity: 1;
}

.submit-act-popup__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--popup-bg-color);
    opacity: 0;
    z-index: 999;
    transition: opacity 0.4s linear;
}

.submit-act-popup__background_active {
    opacity: 1;
    transition: opacity 0.4s linear;
}
.kit-main {
  width: 100%;
  display: flex;
  flex-direction: column;


}

.kit-main__container {
  padding: 0;
}

.kit-main__form {
  width: 100%;
  min-height: 400px;
  height: 75vh;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  overflow: scroll;
}

.kit-main__img-input-label {
  color: var(--text-color) !important;
  font-size: 12px !important;
  line-height: 91.667% !important;
  letter-spacing: -0.72px !important;
}

.kit-main__constructor-block {
  padding: 0;

}

.kit-main__block-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: end;
  gap: 8px;
}

.kit-main__add-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  justify-items: start;
  gap: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  border-radius: 8px;
  background: var(--input-select-bg-color);
  color: var(--text-color);
  box-sizing: border-box;
  padding: 14px 16px;
}

.kit-main__add-btn-icon {
  width: 16px;
  height: 16px;
}

.kit-main__add-btn-icon-fill {
  fill: var(--text-color);
}

@media (max-width: 1000px) {
  .kit-main__form {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content, max-content) 1px minmax(
        min-content,
        max-content
      );
  }
}

.page-heading {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    align-items: center;
    gap: 24px;
    padding: 0 80px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    min-height: 26px;
}

.page-heading__left-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.page-heading__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -1.44px;
    color: var(--text-color);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-heading__go-back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--controls-bg-color);

}

.page-heading__go-back-icon {
    width: 24px;
    height: 24px;
}

.page-heading__go-back-icon-stroke {
    stroke: var(--text-color);
}

.page-heading__right-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
    overflow: hidden;
}

.page-heading__right-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    /* justify-content: flex-end; */
    overflow: scroll;
    padding: 0 16px 0 0;
}

.page-heading__action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px 6px;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.2s linear;
    background-color: var(--btns-bg-color);
}

.page-heading__action:first-of-type{
    margin-left: auto;
}

.page-heading__action_type_main {
    background-color: var(--contrast-color);
}

.page-heading__action_inactive {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}

.page-heading__action-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.72px;
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);
    opacity: 0;
    visibility: hidden;
}

.page-heading__action-text_visible {
    opacity: 1;
    visibility: visible;
}

.page-heading__action_type_main .page-heading__action-text {
    color: var(--secondary-bg-color);
}

.page-heading__action-preloader {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
}

.page-heading__action-preloader_visible {
    opacity: 1;
    visibility: visible;
}

.page-heading__right-controls_mobile {
    display: none;
}

@media (max-width: 880px) {
    .page-heading__title{
        font-size: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 120%;
    }
    .page-heading {
        grid-template-columns: 1fr max-content;
        column-gap: 12px;
        padding: 0 16px;
    }

    .page-heading__right-controls_pc {
        display: none;
    }

    .page-heading__right-controls_mobile {
        display: flex;
    }
    .page-heading__right-controls{
        padding: 0 8px 0 0;
    }
}
.popup-control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--popup-controls-bg-color);
}

.popup-control-btn__icon {
    width: 24px;
    height: 24px;
}

.popup-control-btn__icon-stroke {
    stroke: var(--text-color);
}
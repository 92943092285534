.export-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.export-popup__preloader {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    height: 100%;
}


.export-popup__title {
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    text-align: center;
}

.export-popup__subtitle {
    margin: auto 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 13px */
    letter-spacing: -0.78px;
    text-align: center;
}

.export-popup__btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    letter-spacing: -0.48px;
    padding: 0 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 6px;
}

@media (max-width: 880px) {
    .export-popup {
        padding: 0 16px;
        box-sizing: border-box;
    }
}
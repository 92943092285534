.not-found-error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
}

.not-found-error-page .header {
    background-color: var(--secondary-bg-color) !important;
}

.not-found-error-page .header__link {
    color: var(--header-bg-color) !important;
}

.not-found-error-page .header__link-icon-fill {
    fill: var(--header-bg-color);
}

.not-found-error-page .header__logo-fill {
    fill: var(--header-bg-color);
}

.not-found-error-page .header__current-user-active-arrow-stroke {
    stroke: var(--header-bg-color);
}

.not-found-error-page__img {
    max-width: 298px;
    display: flex;
    margin: auto;
    align-self: center;
}

.not-found-error-page__error-title {
    color: var(--grey-light-border);
    text-align: center;
    font-family: "Golos Text";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 112.5%;
    letter-spacing: 0.96px;
}

.not-found-error-page__img-box {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: auto 0 auto 0;
}

.not-found-error-page__error-subtitle {
    color: var(--text-error);
    text-align: center;
    font-family: "Golos Text";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 112.5%;
    letter-spacing: -0.32px;
    margin-top: 12px;
}

.not-found-error-page .header__current-user-active-shop-name {
    color: var(--header-bg-color);
}

.not-found-error-page__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1360px;
    padding: 16px 0px;
    box-sizing: border-box;
    border-top: 1px solid rgba(94, 56, 207, 0.08);
}

.not-found-error-page__footer-box {
    padding: 0px 40px;
    box-sizing: border-box;
    width: 100%;
}

.not-found-error-page__footer__text {
    color: var(--text-color);
    font-family: "Golos Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 233.333% */
    letter-spacing: -0.48px;
}

@media (max-width:900px) {
    .not-found-error-page__img {
        max-width: 220px;
    }

    .not-found-error-page__error-subtitle {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 112.5% */
        letter-spacing: -0.32px;
    }

    .not-found-error-page__error-title {
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        /* 112.5% */
        letter-spacing: 0.96px;
    }

    .not-found-error-page__footer-box {
        padding: 0 16px;
    }

    .not-found-error-page .header__mobile-menu {
        background: var(--violet-light-bg);
    }
}
.promocode-group{

}

.promocode-group{
    display: flex;
    flex-direction: column;
}

.promocode-group__table{
    margin: 16px auto 0;
    width: 100%;
    max-width: 1440px;
    /* padding: 0 80px; */
    box-sizing: border-box;
}

.promocode-group__preloader {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 880px) {
    .promocode-group__table{
        /* padding: 0 0 0 8px; */
    }
}
.shop-info{
    display: flex;
    flex-direction: column;
}

.shop-info__item-from-container{
    padding: 0;
}

.shop-info__two-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    overflow: hidden;
    height: 75vh;
}

.shop-info__column{
    width: 100%;
    height: 100%;
  
    box-sizing: border-box;
    /* overflow: scroll; */
    height: 100%;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.shop-info__column:first-of-type{
    border-right: 1px solid var(--table-separator-color);
}

@media (max-width: 880px) {
    .shop-info__two-columns {
        min-height: unset;
        grid-template-columns: 1fr;
    }
    .shop-info__column{
        height: unset;
        overflow: hidden;
    }
    .shop-info__column:first-of-type{
        border-right: none;
    }
    
}

.images-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.images-input__heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  box-sizing: border-box;
  padding: 0 16px;
}

.images-input__label {
  color: var(--input-label-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.66px;
}

.images-input__hint {
  color: var(--input-label-color);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.66px;
  opacity: 0.6;
}

.images-input__error {
  color: var(--error-color);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.66px;
}

.images-input__input-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.images-input__img-box {
  max-width: 138px;
  height: fit-content;
  max-height: 200px;
  min-height: 100px;
  position: relative;

}

.images-input__img-box_horizontal {
  width: fit-content;
  max-width: 200px;
  min-width: 100px;
  height: 94px;
}

.images-input__remove-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  border-radius: 4px;
  background: var(--input-dropdown-bg-color);
}

.images-input__img {
  min-width: 100px;
  width: 100%;
  max-height: 100px;
  max-width: 200px;
  object-fit: scale-down;
  border-radius: 4px;
  overflow: hidden;
}

.images-input__add-btn {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  background: var(--input-select-bg-color);
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.images-input__add-btn_disabled {
  pointer-events: none;
  opacity: 0.6;
}

.images-input__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.images-input__add-btn-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
}

.images-input__add-btn-text_visible {
  opacity: 1;
  visibility: visible;
}

.images-input__preloader {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
}

.images-input__preloader_visible {
  opacity: 1;
  visibility: visible;
}

.images-input__read-only-placeholder {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 111.111% */
  letter-spacing: -0.9px;

  color: var(--text-color);
  padding: 14px 16px;
  box-sizing: border-box;
}

@media (max-width: 880px) {
  .images-input__heading {
    padding: 0;
  }
}

@media (max-width: 880px) {
  .images-input__label {
    font-size: 12px;
  }
}
.constructor-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding: 15px 16px 16px;
  width: 100%;
}

.constructor-block__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 91.667%;
  letter-spacing: -0.72px;
  padding-left: 16px;
}

.constructor-block__container {
  width: 100%;
  display: grid;
  gap: 16px;
}

.constructor-block__item {
  width: 100%;
  display: grid;
  align-items: center;
  gap: 8px;
}

.constructor-block__subtitle {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.9px;
  padding-left: 16px;
}

.constructor-block__separator-line {
  width: 100%;
  height: 100%;
  background: var(--table-separator-color);
}

.constructor-block__separator-line_type_long {
  grid-column: 1/-1;
}

@media (max-width: 1250px) {
  .constructor-block__subtitle {
    padding-left: 0;
  }
}

@media (max-width: 880px) {
  .constructor-block__title {
    padding-left: 0;
  }
}

@media (max-width: 600px) {
  .constructor-block__subtitle {
    font-size: 12px;
  }
}

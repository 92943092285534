.notifications-email {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.notifications-email__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}


.notifications-email__hint {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.notifications-email__hint-link{
    color: var(--contrast-color);
    text-decoration: underline;
}

.notifications-email__input-box{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 8px;
    align-items: center;
}

.notifications-email__delete-btn{
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--input-select-bg-color);
    margin-top: calc(14px + 8px);
    border-radius: 4px;
    box-sizing: border-box;
}

.notifications-email__delete-btn-icon{
    width: 100%;
    height: 100%;
}

.notifications-email__delete-btn-icon-fill{
    color: var(--text-color);
}
.product-card__card {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 16px 1fr 80px;
    grid-template-rows: max-content;
    column-gap: 16px;
    border-bottom: 1px solid var(--table-separator-color);
    padding: 15px 16px;
    text-align: left;
    transition: all 0.1s ease-in-out;
}

.product-card__card:last-of-type{
    border: none;
}

.product-card__card_type_no-selectable {
    grid-template-columns: 1fr 80px;
}

.product-card__card_type_main-selected {
    background-color: var(--text-color);
}

.product-card__info-container {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    column-gap: 8px;
}

.product-card__img {
    width: 100%;
    height: 40px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
}

.product-card__img-placeholder{
    background-color: var(--input-select-bg-color);
}

.product-card__card_type_main-selected .product-card__img-placeholder{
    background-color: var(--secondary-bg-color);
}

.product-card__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
}

.product-card__name-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.product-card__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    transition: all 0.1s ease-in-out;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-card__card_type_main-selected .product-card__title {
    color: var(--secondary-bg-color);
}

.product-card__description {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    white-space: pre-line;
    /* 11px */
    letter-spacing: -0.66px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.1s ease-in-out;
}

.product-card__infos{
    display: flex;
    flex-direction:  column;
    gap: 2px;
    margin: 6px 0 0;
}
.product-card__info{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}
.product-card__card_type_main-selected .product-card__description {
    color: var(--secondary-bg-color);
}

.product-card__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    gap: 8px;
    row-gap: 0;
}

.product-card__option-value {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-card__card_type_main-selected .product-card__option-value {
    color: var(--secondary-bg-color);
}

.product-card__option-value_type_no-in-stock {
    opacity: 0.3;
}

.product-card__option-value-amount {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    transition: all 0.1s ease-in-out;
}

.product-card__card_type_main-selected .product-card__option-value-amount {
    color: var(--secondary-bg-color);
}

.product-card__price {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    transition: all 0.1s ease-in-out;
}

.product-card__card_type_main-selected .product-card__price {
    color: var(--secondary-bg-color);
}

.products-card__more-btn {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: all 0.2s linear;
    background-color: var(--controls-bg-color);
  }
  
  .products-card__more-btn_disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
  }
  
  .products-card__more-btn-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.72px;
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);
  }
  
  .products-card__preloader {
    position: absolute;
  }

  @media (max-width: 880px) {
    .product-card__title{
        display: unset;
        -webkit-line-clamp: unset;
        line-clamp: unset;
        -webkit-box-orient: unset;
    }
    .product-card__description{
        display: none;
    }
  }
.finance-info {
    width: 100%;
    display: grid;
    /* grid-template-columns: 1fr max-content; */
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    column-gap: 20px;
    box-sizing: border-box;
    
    width: 100%;
    max-width: 1440px;
    padding: 0 80px;

    box-sizing: border-box;
    margin: 16px 0 0;
}





.finance-info__subscription {
    width: 100%;
    display: grid;
    grid-template-columns: 15fr 10fr 10fr;
    grid-template-rows: max-content;
    padding: 12px 0;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--finance-subscription-bg-info);
}

.finance-info__no-subscription{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.finance-info__no-subscription-text{

}

.finance-info__no-subscription-btn{
    margin: 20px 0 0;
    background-color: var(--contrast-color);
    color: var(--secondary-bg-color);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    width: fit-content;
}


@media (max-width: 880px) {

    .finance-info__subscription {
        display: flex;
        flex-direction: column;
    }
    .finance-info{
        padding: 0 8px;
     }
}

.menu-popup {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 0 8px 0;
    box-sizing: border-box;
}

.menu-popup__heading {
    display: grid;
    grid-template-columns: minMax(0, 1fr) 24px;
    grid-template-rows: max-content;
    column-gap: 8px;
    width: 100%;
    max-width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
}

.menu-popup__heading_type_children {
    grid-template-columns: 24px minMax(0, 1fr) 24px;
    grid-template-rows: max-content;
    column-gap: 8px;
}

.menu-popup__logo {
    width: 91px;
    height: 24px;
}

.menu-popup__logo-fill {
    fill: var(--text-color);
}


.menu-popup__control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--popup-controls-bg-color);
}

.menu-popup__close-icon {
    width: 24px;
    height: 24px;
}

.menu-popup__close-icon-stroke {
    stroke: var(--text-color);
}

.menu-popup__go-back-icon {
    width: 24px;
    height: 24px;
}

.menu-popup__go-back-icon-stroke {
    stroke: var(--text-color);
}

.menu-popup__heading-name {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
    letter-spacing: -1.44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.menu-popup__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px 0 0;
    box-sizing: border-box;
    gap: 0;
}

.menu-popup__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 9px 16px;
    box-sizing: border-box;
    border-radius: 8px;
    transition: all 0.2s linear;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: var(--text-color);
}

.menu-popup__item_selected{
    background-color: var(--text-color);
    color: var(--secondary-bg-color);
}

.menu-popup__item_disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: none;
}
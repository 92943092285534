.cards-controller {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    overflow: scroll;

    box-sizing: border-box;
}

.cards-controller_delete {
    padding: 7px 0 0;
}

.cards-controller__card {
    min-width: 130px;
    height: 86px;
    border-radius: 12px;
    border: 1px solid var(--drop-separator-bg-color);
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
    transition: all 0.2s linear;
    position: relative;
}

.cards-controller__card-selected {
    border: 1px solid var(--violet-light-bg);

    background: var(--selected-payment-bg);
}

.cards-controller__card-heading {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    align-items: center;

}

.cards-controller__card-type {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.24px;
    white-space: nowrap;
    text-align: left;
}

.cards-controller__card-icon {
    width: 26px;
    height: 16px;
}

.cards-controller__card-value {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 116.667% */
    letter-spacing: -0.96px;
    margin: auto 0 0;
    white-space: nowrap;
}

.cards-controller__add {
    min-width: 60px;
    max-width: 60px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--drop-separator-bg-color);
    border-radius: 12px;
}

.cards-controller__add-icon {
    width: 18px;
    height: 18px;
}

.cards-controller__add-icon-stroke {
    stroke: var(--text-color);
}

.cards-controller__card-delete {
    position: absolute;
    z-index: 1;
    right: -7px;
    top: -7px;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--text-color);
    border-radius: 50%;
}

.cards-controller__card-delete-icon {
    width: 8px;
    height: 8px;
}

.cards-controller__card-delete-icon-stroke {
    stroke: var(--secondary-bg-color);
}
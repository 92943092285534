.service-container {
    padding: 8px 16px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--service-container-border-color);
    gap: 8px;
}

.service-container__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 23.4px */
    letter-spacing: -1.08px;
    transition: all 0.2s linear;
}

.service-container__subtitle {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 13.2px */
    letter-spacing: -0.66px;
}

.service-container__title_error {
    color: var(--error-color);
}
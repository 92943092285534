.register-with-shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 53px 0 0;
    height: 100%;
    position: relative;
}

.register-with-shop__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

}

.register-with-shop__change-page {
    /* text-align: center; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.72px;
    align-self: flex-start;
    margin: auto 0 0;
    padding-top: 30px;
}

.register-with-shop__change-page-link {
    font-style: normal;
    font-weight: 500;
    color: var(--contrast-color);
}

.register-with-shop__accept {
    max-width: 258px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.48px;
    margin: 12px 0;
}

.register-with-shop__error{
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.48px;
    color: var(--error-color);
}

.register-with-shop__accept-link{
    
    color: var(--contrast-color);
}

.register-with-shop__form-error{
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.48px;
    color: var(--error-color);
    overflow: hidden;
}
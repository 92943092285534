.prev-orders {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.prev-orders__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.prev-orders__preloader {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev-orders__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    border: 1px solid var(--table-separator-color);
    padding: 8px;
    box-sizing: border-box;
    border-radius: 12px;
}

.prev-orders__card-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.prev-orders__card-number {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
    text-decoration: underline;
}

.prev-orders__card-date-number {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.prev-orders__card-date {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.prev-orders__items {
    height: 300px;
    border: 1px solid var(--table-separator-color);
    padding: 8px;
    box-sizing: border-box;
    border-radius: 12px;
}

.prev-orders__infos {
    display: flex;
    flex-direction: column;
    gap: 8px;

}

.prev-orders__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.prev-orders__info-items {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.prev-orders__info-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.prev-orders__info-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}


.prev-orders__info-item-title {
   

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.prev-orders__info-item-value {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}
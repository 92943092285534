.constant-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    padding: 10px 12px 10px 12px;
    border-radius: 6px;
    border: 1px #EAEBEE solid;
}

.constant-input__heading{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.constant-input__title{
    box-sizing: border-box;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    /* 91.667% */
    letter-spacing: -0.72px;
    color: var(--text-color);
}

.constant-input__toggle{
    margin: 0 0 0 auto;
}
.no-delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
    padding-bottom: 16px;
    transition: all 0.2s linear;
}

.no-delivery_deactiveted {
    opacity: 0.3;
    pointer-events: none;
}

.no-delivery__map {
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    border-radius: 8px;
}
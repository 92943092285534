.main-order-info{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.main-order-info__download{
    width: fit-content;
    min-width: 260px;
    color: var(--text-on-contrast-color);
    background-color: var(--contrast-color);
    border-radius: 8px;
    padding: 8px 16px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    min-height: 38px;
    margin: 0 0 0 auto;
}
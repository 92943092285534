.edit-order-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.edit-order-popup__preloader {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    height: 100%;
}


.edit-order-popup__title {
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    text-align: center;
}

.edit-order-popup__subtitle {
    margin: auto 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 13px */
    letter-spacing: -0.78px;
    text-align: center;
}

.edit-order-popup__btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    letter-spacing: -0.48px;
    padding: 0 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 6px;
}

.edit-order-popup__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    overflow: auto;
}

.edit-order-popup__item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    column-gap: 16px;
    box-sizing: border-box;
    padding: 8px 0;
    border-bottom: 1px solid var(--table-separator-color);
}

.edit-order-popup__item:last-of-type {
    border: none;
}

.edit-order-popup__item-info {
    display: grid;
    width: 100%;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    column-gap: 8px;
}


.edit-order-popup__item-img {
    width: 100%;
    height: 40px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
}

.edit-order-popup__item-img-placeholder {
    background-color: var(--input-select-bg-color);
}

.edit-order-popup__item-name-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.edit-order-popup__item-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 13px */
    letter-spacing: -0.78px;
    transition: all 0.1s ease-in-out;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.edit-order-popup__item-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    gap: 8px;
    row-gap: 0;
}

.edit-order-popup__item-info-text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-order-popup__item-info-amount {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 0 auto;
}

.edit-order-popup__item-info-text_line-throught {
    text-decoration: line-through;
    letter-spacing: 0;
}

.edit-order-popup__item-info-text_bold {
    font-weight: 600;
    letter-spacing: 0;
}

.edit-order-popup__item-amount {
    display: grid;
    grid-template-columns: 24px 24px 24px;
    grid-template-rows: max-content;
    column-gap: 8px;
    margin: 0 0 0 auto;
}

.edit-order-popup__item-amount-controller {
    background-color: var(--input-select-bg-color);
    color: var(--text-color);
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.edit-order-popup__item-amount-value {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
}

.edit-order-popup__item-amount-controller_inactive {
    opacity: 0.1;
    pointer-events: none;
    user-select: none;
}


.edit-order-popup__btns {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 12px;
    flex-direction: column;
    margin: 16px 16px 6px;
  }
  
  
  
  
  .edit-order-popup__btn-submit {
    width: 100%;
    padding: 14px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    border-radius: 8px;
    background-color: var(--two-factor-btn-bg-color);
    color: var(--two-factor-btn-text-color);
  
  }
  
  .edit-order-popup__btn-submit_agree {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    color: var(--two-factor-btn-text-color);
    text-align: center;
  }
  
  .edit-order-popup__btn-submit-text_agree {
    color: var(--two-factor-btn-text-color);
  }
  
  .edit-order-popup__btn-submit_disagree {
    background-color: var(--input-select-bg-color);
    color: var(--text-color);
  }
  
  .edit-order-popup__btn-submit-text_disagree {
    color: var(--text-color);
  }
  

@media (max-width: 880px) {
    .edit-order-popup {
        padding: 0 16px;
        box-sizing: border-box;
    }
}
.recovery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 108px 0 0;
    position: relative;
    height: 100%;
}

.recovery__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.recovery__go-back {
    margin: auto 0 0;
    /* text-align: center; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 111.111% */
    letter-spacing: -0.72px;
    align-self: flex-start;
    padding-top: 30px;

}

.recovery__ok-btn{
    margin: auto 0 0;
}
.technical-domain {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.technical-domain__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}


.technical-domain__hint {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.technical-domain__hint-link {
    color: var(--contrast-color);
    text-decoration: underline;
}

.technical-domain__hint_contrast{
    color: var(--contrast-color);
}

.technical-domain__input-box {
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content;
    column-gap: 8px;
    align-items: center;
}

.technical-domain__main-domain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 16px;
    box-sizing: border-box;
    margin-top: calc(14px + 8px);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
}

.technical-domain__preloader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.product-preview {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  border-radius: 8px;
  background: var(--category-btn-color);
  padding: 0 8px 0 2px;
  transition: all 0.1s ease-in-out;
}

.product-preview_selected {
  background: var(--category-bg-active-color);
}

.product-preview__img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 6px;
}

.product-preview__info {
  flex: auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

.product-preview__title {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.78px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.1s ease-in-out;
  max-width: 100%;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  
}

.product-preview_selected .product-preview__title {
  color: var(--text-on-contrast-color);
}

.product-preview__info-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.product-preview__text {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.78px;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-preview__price{
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.78px;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
}

.product-preview__text_type_opacity {
  color: var(--category-text-color);
  text-transform: uppercase;
}

.product-preview_selected .product-preview__text {
  color: var(--text-on-contrast-color);
}

.product-preview__link {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scaleX(-1);
  border-radius: 50%;
  background: var(--controls-bg-color);
  transition: all 0.1s ease-in-out;
}

.product-preview_selected .product-preview__link {
  background: var(--category-active-link-color);
}

.product-preview__arrow-icon {
  width: 100%;
  height: 100%;
}

.product-preview__icon-stroke {
  stroke: var(--text-color);
  transition: all 0.1s ease-in-out;
}

.product-preview_selected .product-preview__icon-stroke {
  stroke: var(--text-on-contrast-color);
}
.auth-form {
    max-width: 680px;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 700px;
    box-sizing: border-box;
    overflow: scroll;
    border-radius: 12px;
    box-shadow: 0px 14px 14px 0px rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: 2;

}

.auth-form__placeholder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--auth-placeholder-bg-color);
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.auth-form__placeholder-logo {
    position: absolute;
    width: 166px;
    height: 40px;
    top: 30px;
    left: 40px;
    z-index: 2;
}

.auth-form__placeholder-bg {
    position: absolute;
    z-index: 1;
    user-select: none;
    pointer-events: none;

}

.auth-form__placeholder-bg_type_login {
    width: 920px;
    height: 644px;
    left: -281px;
    top: 75px;
}

.auth-form__placeholder-bg_type_signup {
    width: 580px;
    height: 684px;
    left: -62px;
    top: 43px;
}

.auth-form__placeholder-bg_type_recovery {
    width: 634px;
    height: 680px;
    left: -1px;
    top: 88px;
}

.auth-form__placeholder-bg_type_reset-password {
    width: 460px;
    height: 598px;
    top: 90px;
    left: -40px;
}

.auth-form__placeholder-text {
    color: var(--secondary-text-on-contrast-color);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
    letter-spacing: -0.48px;
}

.auth-form__inside {
    background-color: var(--secondary-bg-color);
}

.auth-form__placeholder-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    position: relative;

}

.auth-form__placeholder-content_fade-in {
    animation: 0.2s fadeIn forwards;
}

.auth-form__placeholder-content_fade-out {
    animation: 0.2s fadeOut forwards;
}

.auth-form__placeholder-bg-shape {
    display: none;
    position: absolute;
    z-index: 0;
    width: 578px;
height: 334px;

}

.auth-form__placeholder-bg-shape-fill {
    fill: var(--auth-form-bg-shape-color);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(10px);
    }
}


@media (max-width: 650px) {
    .auth-form {
        min-height: calc(100vh);
        width: 100vw;
        border-radius: 0;
        grid-template-columns: 1fr;
        grid-template-rows: minMax(auto, 250px) 10fr;
    }

    .auth-form__placeholder-logo {
        width: 100px;
        height: 24px;
        top: 30px;
        left: 32px;
    }

    .auth-form__placeholder-bg_type_login {
        width: 540px;
        height: 378px;
        top: 2px;
        left: -55px;
    }

    .auth-form__placeholder-bg_type_signup {
        width: 312px;
        height: 368px;
        top: -25px;
        left: 110px;
    }


    .auth-form__placeholder-bg_type_recovery {
        width: 373px;
        height: 400.065px;
        left: 124px;
        top: -31px;
    }

    .auth-form__placeholder-bg_type_reset-password {
        width: 271.539px;
        height: 353px;
        top: -18px;
        left: 108px;
    }

    .auth-form__placeholder-bg-shape {
        display: block;
        top: 74px;
        left: -171px;
    }

    /* .auth-form__placeholder {
        display: none;
    } */
}
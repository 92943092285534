.reset-password{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 140px 0 0;
    position: relative;
}

.reset-password__form{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.reset-password__form-error{
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.48px;
    color: var(--error-color);
}
.zone-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 400px;
}

.zone-popup__heading{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    align-items: center;
}

.zone-popup__title{
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.zone-popup__number{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: var(--bg-color);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    text-align: center;
}

@media (max-width: 880px) {
   .zone-popup{
    padding: 0 16px;
    box-sizing: border-box;
   }
}
.internal-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
}

.internal-links__heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 16px 14px 24px;
    box-sizing: border-box;
}

.internal-links__title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--table-title-color);
}

.internal-links__links {
    display: flex;
    flex-direction: column;
    width: 100%;

}


.internal-links__link {
    padding: 16px 16px 16px 24px;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
    transition: all 0.1s ease-in-out;
    border-top: 1px solid var(--table-separator-color);
}

.internal-links__link_active {
    background-color: var(--text-color);
    color: var(--secondary-bg-color);
}

.internal-links__link_inactive{
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}
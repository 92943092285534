.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;

    padding: 0 calc(80px - 32px);
    margin-top: -32px;
    box-sizing: border-box;

}

.table .product-form-placeholder__create {
    display: flex;
    align-self: center;
    margin: 20px 0 0 0;
}

.table__title {

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: var(--text-color);
    margin: 0;
    padding: 0 0 0 32px;
}



.table__table-container {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    overflow: auto !important;
    padding: 32px;
    /* border-radius: 16px 0 0 16px; */
    height: 100%;
    position: relative;

}

.table__table-box-shadow {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    border-radius: 16px;
    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
    z-index: 0;
}

.table__table {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    overflow: auto !important;
    padding: 0 32px 0;
    border-radius: 16px;

    background-color: var(--secondary-bg-color);

}


.table__table-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.table__table-container::-webkit-scrollbar-track {
    border: none;
    outline: none;
    display: none;
    background-color: transparent !important;
}

.table__table-container::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    outline: none;
    display: none;
    border-radius: 100px;
}



.table__thead-th {
    color: var(--table-title-color);
    padding: 14px 16px 14px 0;
    width: fit-content;
    text-align: left;
    box-sizing: border-box;
    white-space: nowrap;
    vertical-align: text-top;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
}



.table__thead-th_referrals-amount {
    white-space: unset;
    max-width: 120px;
}


.table__thead-th_request-number {
    width: fit-content;
}


.table__thead-th:first-of-type {
    padding-left: 16px;
}

.table__thead-th:last-of-type {
    text-align: right;
    padding-right: 16px;
}

.table__tbody-tr {
    border-top: 1px solid var(--table-separator-color);
}




.table__tbody-td {
    /* max-width: 242px; */

    width: fit-content;
    padding: 15px 60px 15px 0;
    box-sizing: border-box;
    white-space: nowrap;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.table__tbody-td-link {
    color: var(--contrast-color);
    text-decoration: underline;
    width: fit-content;
}


.table__tbody-td:first-of-type {
    padding-left: 16px;
}

.table__tbody-td:last-of-type {
    text-align: right;
    padding-right: 16px;
}

.table__tbody-td_status {
    width: 80px;
}

.table__tbody-td-row {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

}

.table__tbody-td-row_right {
    width: 100%;
    justify-content: flex-end;
}

.table__nothing-found {
    padding: 0 32px;
    color: var(--text-color);


    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin: 34px auto 0;
    text-align: center;
    white-space: pre-line;
}

.table__tbody-td-row-link-value {
    color: var(--text-color);

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin: 0 12px 0 0;
    cursor: pointer;
}

.table__tbody-td-row-link-value_right {
    margin: 0;
    text-align: right;
}


.table__tbody-td-row-link-copy {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.table__tbody-td-row-link-copy-fill {
    fill: var(--text-color);
    opacity: 0.6;
}

.table__preloader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
}

.table__thead-th_right {
    text-align: right;
}

.table__column {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.table__first-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--text-color);
}

.table__last-name {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--text-color);
}

.table__email {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    text-decoration-line: underline;
    max-width: 135px;
    white-space: nowrap;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
}

.table__phone {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    text-decoration-line: underline;

    white-space: nowrap;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
}



.table__text_type_title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.table__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
}


.table__control {
    padding: 5px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    transition: all 0.2s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table__control_type_link {
    background-color: var(--action-control-type-link-bg);
    color: var(--secondary-bg-color);
}

.table__control_type_button {
    background-color: var(--controls-bg-color);
    color: var(--text-color);
}

.table__control_inactive {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}


@media (max-width: 920px) {
    .table__title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        padding: 0 12px;
    }

    /* .table__table-container {
        padding: 0 12px;
    } */


    .table__nothing-found {
        padding: 0 12px;
    }
}

@media (max-width: 880px) {
    .table {
        padding: 0;
        margin: 0;
    }

    .table__table-container {
        padding: 0 8px;

    }

    .table__table-box-shadow {
        box-shadow: unset;
        border-radius: unset;
        width: 100%;
        min-width: fit-content;

    }
}

@media (max-width: 560px) {


    .refferals-list__title {
        padding: 12px 16px 0;
    }
}
.account-main {
    display: flex;
    flex-direction: column;

}

.account-main__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 75vh;
    overflow: scroll;
}

.account-main__personal {
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--table-separator-color);
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.account-main__shop-info {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.account-main__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 91.667% */
    letter-spacing: -0.72px;
    color: var(--text-color);
}

@media (max-width: 880px) {
 .account-main__content{
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 32px;
 }
 .account-main__personal{
    padding: 0;
 }
 .account-main__shop-info{
    padding: 0;
    overflow: visible;
 }
}
.custom-tooltip{
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 4px;
    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
    background-color: var(--secondary-bg-color);
    box-sizing: border-box;
    padding: 8px 12px;
}

.custom-tooltip__title{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
}

.custom-tooltip__value{

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    letter-spacing: -1.44px;
    color: var(--text-color);
}
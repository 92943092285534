.info-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    border: 1px solid var(--table-separator-color);
    padding: 8px;
    box-sizing: border-box;
    border-radius: 12px;
}

.info-box__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}

.info-box__infos {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.info-box__info {
    width: 100%;
    display: flex;
    flex-direction: row;

    gap: 8px;

}

.info-box__info_type_map {
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
}

.info-box__info-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.info-box__info-value {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.info-box__info-value_link {
    text-decoration: underline;
}

.info-box__status {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.info-box__status-current {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    grid-template-rows: max-content;
    column-gap: 16px;

}

.info-box__btn {
    background-color: var(--input-select-bg-color);
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    color: var(--text-color);
    padding: 6px 12px;
}

.info-box__map {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    border-radius: 8px;
}
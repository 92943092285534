.own-delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
    padding-bottom: 16px;
    transition: all 0.2s linear;
}

.own-delivery_deactiveted {
    opacity: 0.3;
    pointer-events: none;
    
}

.own-delivery__map {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    border-radius: 8px;
}

.own-delivery__hint {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.own-delivery__hint-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -1.08px;
    transition: all 0.2s linear;
}

.own-delivery__hint-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.66px;
}

.own-delivery__form-and-map {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: max-content;
    column-gap: 16px;
}

.category {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.category__container {
  height: 100%;
  min-height: 628px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  padding: 16px 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

@media (max-width: 750px) {
  .category__container {
    width: 100%;
    min-height: unset;
    grid-template-columns: 1fr;
  }
}

.filter-controls {
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 12px;
}


.filter-controls__btn{

    padding: 8px 9px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 228.571% */
    letter-spacing: -0.56px;
    transition: all 0.2s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--btns-bg-color);
    color: var(--text-color);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.filter-controls__btn_type_main {
    background-color: var(--contrast-color);
    color: var(--text-on-contrast-color);
}


.filter-controls__btn_inactive{
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}
.custom-domain {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.custom-domain__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}


.custom-domain__hint {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.custom-domain__hint-link {
    color: var(--contrast-color);
    text-decoration: underline;
}

.custom-domain__info-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 13.2px */
    letter-spacing: -0.66px;
    transition: all 0.2s linear;
}




.custom-domain__table {
    position: relative;
    z-index: 1;
    width: fit-content;
    max-width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    overflow: auto !important;
    padding: 16px 0;

    background-color: var(--secondary-bg-color);
    min-width: 348px;
}


.custom-domain__table-container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.custom-domain__table-container::-webkit-scrollbar-track {
    border: none;
    outline: none;
    display: none;
    background-color: transparent !important;
}

.custom-domain__table-container::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    outline: none;
    display: none;
    border-radius: 100px;
}



.custom-domain__thead-th {
    color: var(--table-title-color);
    padding: 8px 8px 8px 0;
    width: fit-content;
    text-align: left;
    box-sizing: border-box;
    white-space: nowrap;
    vertical-align: text-top;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
}



.custom-domain__thead-th_referrals-amount {
    white-space: unset;
    max-width: 120px;
}


.custom-domain__thead-th_request-number {
    width: fit-content;
}


/* .custom-domain__thead-th:first-of-type {
    padding-left: 16px;
}

.custom-domain__thead-th:last-of-type {
    text-align: right;
    padding-right: 16px;
} */

.custom-domain__tbody-tr {}




.custom-domain__tbody-td {
    /* max-width: 242px; */

    width: fit-content;
    padding: 8px 8px 8px 0;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    color: var(--text-color);
}

.custom-domain__tbody-td-link {
    color: var(--contrast-color);
    text-decoration: underline;
    width: fit-content;
}


/* .custom-domain__tbody-td:first-of-type {
    padding-left: 16px;
}

.custom-domain__tbody-td:last-of-type {
    text-align: right;
    padding-right: 16px;
} */

.custom-domain__tbody-td_status {
    width: 80px;
}


.custom-domain__info-text_error{
    color: var(--error-color);
}



@media (max-width: 880px) {

    .custom-domain__table-container {
        padding: 0 8px;

    }

}
.platform-input__input-container {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */

  width: 100%;
  box-sizing: border-box;
  gap: 8px;
}

.platform-input__input-heading {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.platform-input__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 91.667% */
  letter-spacing: -0.72px;
  color: var(--input-label-color);
  white-space: nowrap;
}

.platform-input__hint {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 91.667% */
  letter-spacing: -0.72px;
  color: var(--input-label-color);
  text-align: right;
  opacity: 0.6;
}

.platform-input__error {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 91.667% */
  letter-spacing: -0.72px;
  color: var(--error-color);
  text-align: right;
}

.platform-input__select-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.platform-input__select-selected {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  grid-template-rows: max-content;
  /* column-gap: 16px; */
  padding: 14px 16px;
  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: var(--input-select-bg-color);
  width: 100%;
  border-radius: 8px;
  transition: all 0.2s linear;
  position: relative;
  z-index: 2;
}

.platform-input__input-box_no-change {
  border: none !important;
}

.platform-input__select-selected-value {
  width: 100%;
  background: transparent;
  text-align: left;
  border: none;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 111.111% */
  letter-spacing: -0.9px;

  color: var(--text-color);
  box-sizing: border-box;
  margin-top: unset;

  padding: 0;

  transition: all 0.2s linear;
  caret-color: var(--input-border-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.platform-input__select-arrow {
  width: 12px;
  height: 12px;
  transition: all 0.2s linear;
}

.platform-input__select-selected_open .platform-input__select-arrow {
  transform: rotate(180deg);
}

.platform-input__select-arrow-stroke {
  stroke: var(--text-color);
}

.platform-input__box-with-dropdown {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  position: relative;
}

.platform-input__dropdown {
  width: 100%;
  box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
  border-radius: 8px;
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  z-index: 100;
  overflow: scroll;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  max-height: 300px;
}


.platform-input__dropdown_opened {
  visibility: visible;
  box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
  opacity: 1;
}


.platform-input__dropdown-container {

  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--input-dropdown-bg-color);
  box-sizing: border-box;
  z-index: 100;
}


.platform-input__dropdown-list {
  width: 100%;
  display: grid;
  position: relative;
  z-index: 100;
}

.platform-input__dropdown-item {
  width: 100%;
}

.platform-input_dropdown-btn {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 16px;
  text-align: left;
  border: none;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 111.111% */
  letter-spacing: -0.9px;

  color: var(--text-color);

}

.platform-input_dropdown-item_selected .platform-input_dropdown-btn {
  background-color: var(--text-color);
  color: var(--input-dropdown-bg-color);
}

.platform-input__input-box {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  column-gap: 16px;
  padding: 14px 16px;
  box-sizing: border-box;
  border: 1px solid var(--input-border-color);
  width: 100%;
  border-radius: 8px;
  transition: border-color 0.2s linear;
  position: relative;
  z-index: 2;
}

.platform-input__input-box_with-preloader {
  grid-template-columns: 1fr 16px;
}



.platform-input__input-box_with-icon {
  grid-template-columns: 1fr min-content;
}

.MuiInputBase-root {
  padding: 12px 16px !important;
  box-sizing: border-box !important;
  border: 1px solid var(--input-border-color);
  width: 100%;
  border-radius: 8px !important;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiInputBase-input {
  width: 100% !important;
  background: transparent !important;
  ;

  border: none !important;


  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  /* 111.111% */
  letter-spacing: -0.9px !important;

  color: var(--text-color) !important;
  box-sizing: border-box !important;
  margin-top: unset !important;

  overflow: auto !important;
  padding: 0 !important;

  transition: all 0.2s linear !important;
  caret-color: var(--input-border-color) !important;
}



.platform-input__input {

  width: 100%;
  background: transparent;

  border: none;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 111.111% */
  letter-spacing: -0.9px;

  color: var(--text-color);
  box-sizing: border-box;
  margin-top: unset;

  overflow: auto;
  padding: 0;

  transition: color 0.1s linear;
  caret-color: var(--input-border-color);
}

.platform-input__input_type_text-area {
  resize: none;
  overflow: auto;
  width: 100%;
}

.platform-input__input::placeholder {
  color: var(--input-border-color);
}

.platform-input__input:focus {
  outline: transparent;
  caret-color: var(--text-color);
}

.MuiInputBase-root:focus {
  outline: transparent !important;
  caret-color: var(--text-color) !important;
  ;
}


.platform-input__input-box_focused {
  border-color: var(--text-color) !important;
}

.platform-input__input-box_correct-state {
  grid-template-columns: 1fr min-content;
  border-color: var(--ion-green) !important;
}

.platform-input__tick {
  width: 13px;
  height: 16px;
}

.platform-input__tick-fill {
  fill: var(--ion-green);
}

.platform-input__input-submit {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  border-radius: 7px;
  background-color: var(--secondary-bg-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 20px */
  letter-spacing: -0.8px;
  cursor: no-drop;
  transition: all 0.2s linear;
  pointer-events: none;
}

.platform-input__input-submit_active {
  background-color: var(--secondary-active-bg-color);
  color: var(--contrast-color);
  cursor: pointer;
  pointer-events: all;
}

.platform-input__input-box_error {
  border-color: var(--error-color) !important;
}

.platform-input__input-box_error .platform-input__input {
  color: var(--error-color) !important;
}

.platform-input__input-box_error .platform-input__input-submit {
  opacity: 0.3;
}

.platform-input__show-btn {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.platform-input__show-btn-icon {
  width: 24px;
  height: 24px;
}

.platform-input__show-btn-icon-fill {
  fill: var(--text-color);
}


.platform-input__input-box_error {
  border-color: var(--error-color);
}

.MuiButtonBase-root {
  /* display: none !important; */
}

.MuiSvgIcon-root {
  /* width: 15px !important;
  height: 15px !important; */
}

.platform-input__boolean-selector {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minMax(0, 1fr));
  grid-template-rows: max-content;
  border-radius: 8px;
  overflow: hidden;
}

.platform-input__boolean-selector-item {
  width: 100%;
  color: var(--boolean-selector-false-text-color);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 15px */
  letter-spacing: -0.9px;
  background-color: var(--boolean-selector-false-bg-color);
  padding: 14px 16px;
  box-sizing: border-box;
  transition: background-color color 0.1s ease-in-out;
}

.platform-input__boolean-selector-item_selected {
  color: var(--boolean-selector-true-text-color);
  background-color: var(--boolean-selector-true-bg-color);
}

.platform-input__categories-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 9px;
  box-sizing: border-box;
  flex-wrap: wrap;
  border: 1px solid var(--input-border-color);
  width: 100%;
  border-radius: 8px;
  gap: 9px;
  /* row-gap: 4px; */
}

.platform-input__categories-input_read-only {
  border: 1px solid transparent;
}

.platform-input__category {
  padding: 4px 2px 4px 12px;
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: var(--input-select-bg-color);
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 12px */
  letter-spacing: -0.72px;
  color: var(--text-color);
  position: relative;
}


.platform-input__categories-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.platform-input__category-delete {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.platform-input__category-delete-icon {
  width: 5px;
  height: 5px;
}

.platform-input__category-delete-icon-fill {
  fill: var(--text-color);
}

.platform-input__category-add {
  background-color: var(--input-select-bg-color);
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 12px */
  letter-spacing: -0.72px;
  color: var(--text-color);
  padding: 6px 12px;
}

.platform-input__category-read-only-placeholder {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 111.111% */
  letter-spacing: -0.9px;

  color: var(--text-color);
  padding: 0 7px;
  box-sizing: border-box;
}

@media (max-width: 880px) {
  .platform-input__input-heading {
    padding: 0;

    display: grid;
    grid-template-columns: 100px 1fr;
  }

  .platform-input__label {
    font-size: 12px;
  }

  .platform-input__hint {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
    margin-left: 20%;
  }

  .platform-input__select-selected {
    padding: 14px 8px;
  }

  .platform-input__input-box {
    padding: 14px 8px;
  }

  .platform-input__boolean-selector-item {
    padding: 14px 0;
  }

}
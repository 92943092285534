.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--header-bg-color);
    box-sizing: border-box;
    padding: 18px 40px;

}

.header__content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header__main-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.header__logo-and-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.header__logo-link {}

.header__logo {
    width: 137px;
    height: 36px;
}

.header__logo-fill {
    fill: var(--header-text-color);
}

.header__links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header__link {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 228.571% */
    letter-spacing: -0.56px;
    padding: 9px 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 40px;
    color: var(--header-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header__link_active {
    background-color: var(--contrast-color);
    color: var(--secondary-bg-color);

}

.header__second-level-links {
    margin: 8px 0 0;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: var(--second-level-header);
}

.header__second-level-link {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 233.333% */
    height: 28px;
    letter-spacing: -0.48px;
    padding: 0 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 50px;
    color: var(--header-text-color);
}

.header__second-level-link_active {
    background-color: var(--contrast-color);
    color: var(--secondary-bg-color);

}

.header__second-level-link_disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: none;
}

.header__mobile-menu-and-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.header__mobile-menu {
    display: none;
}

.header__mobile-menu-text {
    color: var(--secondary-bg-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.header__mobile-menu-arrow {
    width: 12px;
    height: 12px;
    transform: rotate(-180deg);
    transition: all 0.2s linear;
}

.header__mobile-menu_opened .header__mobile-menu-arrow {
    transform: rotate(0deg);
}

.header__mobile-menu-arrow-stroke {
    stroke: var(--secondary-bg-color);
}

.header__link-icon {}

.header__link-icon-fill {
    fill: var(--header-text-color);
}

.header__mobile-menu-icon {
    width: 16px;
    height: 16px;
}

.header__mobile-menu-icon-fill {
    fill: var(--header-text-color);
}

.header__current-user-box {
    display: flex;
    flex-direction: column;
    position: relative;
}

.header__current-user-active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px 2px 2px;
    box-sizing: border-box;
    border-radius: 110px;
    transition: all 0.2s ease-in-out;
}

.header__current-user-active_open {
    background-color: var(--second-level-header);
}

.header__current-user-active-shop-icon {
    width: 28px !important;
    height: 28px !important;
}

.header__current-user-active-shop-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.56px;
    margin-left: 8px;
    color: var(--header-text-color);
    white-space: nowrap;
    max-width: 104px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header__current-user-active-shop-name-separator {
    margin: 0 7px;
    box-sizing: border-box;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--header-text-color);
    opacity: 0.4;
}

.header__current-user-active-arrow {
    width: 10px;
    height: 10px;
    margin-left: 4px;
    transform: rotate(-180deg);
    transition: all 0.2s ease-in-out;
}

.header__current-user-active_open .header__current-user-active-arrow {
    transform: rotate(0);
}

.header__current-user-active-arrow-stroke {
    stroke: var(--header-text-color);
}

.header__current-user-active-shop-id {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.48px;
    color: var(--header-text-color);
}

.header__box-with-dropdown {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 10px;
    position: relative;
}

.header__dropdown {
    box-shadow: 0px 13px 26px 0px rgba(150, 152, 171, 0.23);
    border-radius: 6px;
    position: absolute;
    min-width: 220px;
    width: calc(100% + 18px + 26px);
    top: calc(100% + 6px);
    right: -26px;
    z-index: 100;
    overflow: scroll;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    max-height: calc(100vh - 75px - 48px - 24px);
}


.header__dropdown_opened {
    visibility: visible;
    opacity: 1;
}

.header__dropdown-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: var(--secondary-bg-color);
    box-sizing: border-box;
    padding: 20px 0 0 0;
    z-index: 100;
}

.header__dropdown-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.header__dropdown-heading-avatar {
    width: 52px !important;
    height: 52px !important;
}

.header__dropdown-heading-user-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.28px;
    text-align: center;
    margin: 8px 0 0;
    padding: 0 16px;
    box-sizing: border-box;
}

.header__dropdown-heading-user-email {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: -0.2px;
    text-align: center;
    margin: 2px 0 0;
    max-width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    box-sizing: border-box;
}

.header__dropdown-heading-account {
    width: 100%;
    padding: 0 17px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-template-rows: max-content;
    column-gap: 13px;
    margin: 20px 0 0;
    align-items: center;

}

.header__dropdown-heading-account-icon {
    width: 22px;
    height: 22px;
}

.header__dropdown-heading-account-icon-stroke {
    stroke: var(--text-color);
}

.header__dropdown-heading-account-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.28px;
}

.header__dropdown-shops {
    width: 100%;
    padding: 4px 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-top: 1px solid var(--drop-separator-bg-color);
    border-bottom: 1px solid var(--drop-separator-bg-color);
}

.header__dropdown-shop {
    width: 100%;
    padding: 6px 10px 6px 8px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 32px 1fr 14px;
    align-items: center;
    grid-template-rows: max-content;
    column-gap: 8px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
}

.header__dropdown-shop:hover {
    background-color: var(--drop-hover-bg-color);
}

.header__dropdown-shop-icon {
    width: 32px !important;
    height: 32px !important;
}

.header__dropdown-shop-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
}

.header__dropdown-shop-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.28px;
    white-space: nowrap;
    text-align: left;
    max-width: 100%;
    overflow: hidden;
}

.header__dropdown-shop-id-and-role {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: -0.2px;
    opacity: 0.7;
    text-align: left;
}

.header__dropdown-shop-selected-icon {
    width: 14px;
    height: 14px;
}

.header__dropdown-shop-selected-icon-stroke {
    stroke: var(--text-color);
}

.header__dropdown-logout {
    width: 100%;
    padding: 11px 17px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.header__dropdown-logout-btn {
    width: 100%;
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-template-rows: max-content;
    column-gap: 13px;
    align-items: center;
}

.header__dropdown-logout-icon {
    width: 22px;
    height: 22px;
}

.header__dropdown-logout-icon-stroke {
    stroke: var(--text-color);
}

.header__dropdown-logout-btn-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: -0.28px;
    text-align: left;
}



@media (max-width: 1155px) {


    .header__logo {
        width: 106px;
        height: 28px;
    }
}

@media (max-width: 960px) {
    .header__link {
        font-size: 12px;
    }
}


@media (max-width: 880px) {
    .header__logo {
        width: 82px;
        height: 20px;
    }

    .header__links {
        display: none;
    }

    .header__mobile-menu {
        display: grid;
        grid-template-columns: 1fr 12px;
        grid-template-rows: max-content;
        column-gap: 10px;
        padding: 10px 16px;
        align-items: center;
        box-sizing: border-box;
        width: 200px;
        background-color: var(--contrast-color);
        color: var(--secondary-bg-color);
        border-radius: 40px;
    }

    .header__main-row {
        gap: 12px;
    }

    .header__second-level-links {
        display: none;
    }

    .header {
        padding: 12px 16px;
        box-sizing: border-box;
    }

    .header__current-user-active-shop-name {
        display: none;
    }

    .header__current-user-active {
        position: relative;
        padding: 0;
    }

    .header__current-user-active-shop-icon {
        width: 36px !important;
        height: 36px !important;
    }

    .header__current-user-active-arrow {
        margin: 0;
        position: absolute;
        top: 13px;
        right: -4px;
        width: 10px;
        height: 10px;
        padding: 2px;
        box-sizing: border-box;
        background-color: var(--header-text-color);
        border-radius: 50%;
    }

    .header__current-user-active-arrow-stroke {
        stroke: var(--header-bg-color);
    }

    .header__dropdown {
        right: 0;
        top: calc(100% + 12px);
    }

    .header__mobile-menu-text_default {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
.create-promocode-group {
    display: flex;
    flex-direction: column;
}

.create-promocode-group_form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 32px;
}

.create-promocode-group_form-column{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media (max-width: 880px) {
    .create-promocode-group_form{
        grid-template-columns: 1fr;
    }
}

.product-form-options__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.product-form-options {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-template-rows: max-content;
    column-gap: 8px;
}

.product-form-options_with-amount {
    grid-template-columns: 2fr 7fr;
}

.product-form__btn {
    padding: 14px 16px;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 10px;
    background-color: var(--product-form-btn-bg-color);
    color: var(--product-form-btn-text-color);
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    border-radius: 8px;
}

.product-form__btn-icon {
    width: 16px;
    height: 16px;
}

.product-form__btn-icon-fill {
    fill: var(--product-form-btn-text-color);
}

.product-form__option-values-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.product-form__option-values {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-template-rows: max-content;
    column-gap: 8px;
    align-items: center;
}


.product-form-options_with-amount .product-form__option-values {
    grid-template-columns: 65fr 35fr 35fr 80px;
    grid-template-rows: max-content;
}

.product-form__btn_option-values {
    background-color: var(--input-select-bg-color);
    color: var(--text-color);
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    grid-template-columns: 1fr;
    border-radius: 4px;
}

.product-form__option-value-controls {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 4px;
}

.product-form__option-value-controls_first {
    padding: 20px 0 0;
    box-sizing: border-box;
}

.product-form__option-value-control {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--input-select-bg-color);

}

.product-form__option-value-control_inactive{
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
}


.product-form__option-value-control-icon {
    width: 100%;
    height: 100%;
}

.product-form__option-value-control-icon-fill {
    fill: var(--text-color);
}

.product-form__option-value-control-icon_up{
    transform: rotate(180deg);
}


@media (max-width: 880px) {
    .product-form-options_with-amount .product-form__option-values {
        grid-template-columns: 80px 80px 80px 80px;
        grid-template-rows: max-content;
    }
    
}
.avatar-box {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
    transition: all 0.2s linear;
}

.avatar-box_no-img {
    background-color: var(--avatar-placeholder-bg-color);
}

.avatar-box__placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.avatar-box__placeholder-initials {
    text-align: center;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    /* 112.5% */
    letter-spacing: -0.96px;
    color: var(--avatar-placeholder-text-color);
}


.avatar-box__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.statistics-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.statistics-info__title {
    color: var(--text-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 31.2px */
    letter-spacing: -1.44px;
}

.statistics-info_type_negative .statistics-info__title {
    color: var(--text-negative-color);
}

.statistics-info__change {
    color: var(--secondary-bg-color);
    background-color: var(--text-color);
    border-radius: 2px;
    padding: 4px;
    box-sizing: border-box;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 12px */
    letter-spacing: -0.72px;
    white-space: nowrap;
}

.statistics-info_type_negative .statistics-info__change{
    background-color: var(--text-negative-color);
}

@media (max-width: 880px) {
 .statistics-info__title{
    font-size: 16px;
 }
 .statistics-info__change{
    padding: 3px 4px;
    font-size: 11px;
 }
}
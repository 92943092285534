.system-constants {
    display: flex;
    flex-direction: column;
}

.system-constants__item-from-container {
    width: 100%;
    min-height: 287px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.system-constants__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (max-width: 880px) {
    .system-constants__item-from-container {
        min-height: unset;
        grid-template-columns: 1fr;
    }
}



.system-constants__items {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}
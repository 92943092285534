.address-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    gap: 16px;
}

.address-form__extra {
    position: relative;
    z-index: 1;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    column-gap: 12px;

}

.address-form__comment{
    position: relative;
    z-index: 1;
    width: 100%;
}
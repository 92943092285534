.mobile-popup__container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    visibility: hidden;
    left: 0;
    top: 0;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}


.mobile-popup__container_active {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.mobile-popup {
    background: var(--secondary-bg-color);


    position: fixed;
    bottom: -100vh;
    top: unset;
    left: 0;
    z-index: 2;
    width: 100%;
    height: fit-content;
    border-radius: 16px 16px 0px 0px;
    padding: 16px 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    transition: transform 0.3s ease-in-out;
}

.mobile-popup_active {
    transform: translateY(-100vh);
    animation: unset;
}


.mobile-popup__bg {
    transition: all 0.2s linear;

    background: var(--popup-bg-color);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    opacity: 0;
}

.mobile-popup__container_active .mobile-popup__bg{
    opacity: 0.4;
}
.category-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.category-input__heading {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.category-input__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  color: var(--input-label-color);
  white-space: nowrap;
}

.category-input__hint {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  color: var(--input-label-color);
  text-align: right;
  opacity: 0.6;
}

.category-input__error {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  color: var(--error-color);
  text-align: right;
}

.category-input__container {
  width: 100%;
  position: relative;
  display: flex;
}

.category-input__input-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 9px;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  padding: 10px 9px;
  box-sizing: border-box;
}

.category-input__input-box_read-only {
  border: 1px solid transparent;
}

.category-input__category {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  background-color: var(--input-select-bg-color);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 2px 4px 12px;
}

.category-input__category-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
}

.category-input__delete-btn {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-input__delete-icon {
  width: 5px;
  height: 5px;
}

.category-input__delete-icon-fill {
  fill: var(--text-color);
}

.category-input__add-btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.72px;
  color: var(--text-color);
  background-color: var(--input-select-bg-color);
  border-radius: 4px;
  padding: 6px 12px;
}

.category-input__dropdown {
  width: 100%;
  max-width: 375px;
  max-height: 300px;
  position: absolute;
  top: calc(100% - 34px);
  right: -10px;
  box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
  border-radius: 8px;
  overflow: scroll;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}

.category-input__dropdown_opened {
  visibility: visible;
  opacity: 1;
}

.category-input__box-with-dropdown {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  position: relative;
}

.category-input__dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  background-color: var(--secondary-bg-color);
  box-sizing: border-box;
  padding: 16px 8px;
  z-index: 100;
}

.category-input__dropdown-heading {
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  grid-template-rows: max-content;
  align-items: center;
  column-gap: 8px;
}

.category-input__dropdown-control-btn {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--popup-controls-bg-color);
  opacity: 1;
  visibility: visible;
  transition: all 0.15s ease-in-out;
}

.category-input__dropdown-control-btn_hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}

.category-input__control-btn-icon {
  width: 100%;
  height: 100%;
}

.category-input__control-btn-icon-stroke {
  stroke: var(--text-color);
}

.category-input__dropdown-title {
  max-width: 100%;
  white-space: nowrap;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1.44px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-input__dropdown-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
}

.category-input__dropdown-item {
  width: 100%;
}

.category-input__cat-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content;
  align-items: center;
  column-gap: 10px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 8px 16px;
  transition: all 0.15s ease-in-out;
}

.category-input__cat-btn_disabled {
  pointer-events: none;
  user-select: none;
}

.category-input__dropdown-item_selected .category-input__cat-btn {
  background-color: var(--text-color);
  pointer-events: none;
  user-select: none;
}

.category-input__cat-btn-text {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.84px;
  transition: all 0.15s ease-in-out;
}

.category-input__dropdown-item_selected .category-input__cat-btn-text {
  color: var(--secondary-bg-color);
}

.category-input__cat-type {
  display: grid;
  grid-template-columns: max-content fit-content(13px);
  grid-template-rows: max-content;
  align-items: center;
  column-gap: 4px;
}

.category-input__cat-type-text {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.84px;
  transition: all 0.15s ease-in-out;
}

.category-input__dropdown-item_selected .category-input__cat-type-text {
  color: var(--secondary-bg-color);
}

.category-input__cat-type-icon {
  width: 13px;
  height: 13px;
}

.category-input__cat-type-icon-fill {
  fill: var(--text-color);
}

.category-input__dropdown-item_selected .category-input__cat-type-icon-fill {
  fill: var(--secondary-bg-color);
}

.category-input__preloader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-input__read-only-placeholder{
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 111.111% */
  letter-spacing: -0.9px;

  color: var(--text-color);
  padding: 0 7px;
  box-sizing: border-box;
}

@media (max-width: 880px) {
  .category-input__dropdown {
    right: 0;
  }
}
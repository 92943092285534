.edit-products-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.edit-products-popup__preloader {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    height: 100%;
}

.edit-products-popup__heading {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-template-rows: max-content;
    column-gap: 12px;
    align-items: center;
}

.edit-products-popup__title {
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 13px */
    letter-spacing: -0.78px;
    width: 100%;
}

.edit-products-popup__close {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* background-color: var(--input-select-bg-color); */
}

.edit-products-popup__close-icon {
    width: 100%;
    height: 100%;
}

.edit-products-popup__close-icon-stroke {
    stroke: var(--text-color);
}


.edit-products-popup__subtitle {
    margin: auto 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 13px */
    letter-spacing: -0.78px;
    text-align: center;
}

.edit-products-popup__btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    letter-spacing: -0.48px;
    padding: 0 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 6px;
}

.edit-products-popup__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    overflow: auto;
}

.edit-products-popup__item {
    width: 100%;
    display: grid;
    grid-template-columns: 10px 1fr 24px;
    grid-template-rows: max-content;
    column-gap: 16px;
    box-sizing: border-box;
    padding: 8px 0;
    border-bottom: 1px solid var(--table-separator-color);
}


.edit-products-popup__item:last-of-type {
    border-bottom: 1px solid transparent;
}

.edit-products-popup__item-info {
    display: grid;
    width: 100%;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    column-gap: 8px;
}


.edit-products-popup__item-img {
    width: 100%;
    height: 40px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
}

.edit-products-popup__item-img-placeholder {
    background-color: var(--input-select-bg-color);
}

.edit-products-popup__item-name-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.edit-products-popup__item-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 13px */
    letter-spacing: -0.78px;
    transition: all 0.1s ease-in-out;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.edit-products-popup__item-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    gap: 8px;
    row-gap: 0;
}

.edit-products-popup__item-info-text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-products-popup__item-info-amount {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 11px */
    letter-spacing: -0.66px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 0 auto;
}

.edit-products-popup__item-info-text_line-throught {
    text-decoration: line-through;
    letter-spacing: 0;
}

.edit-products-popup__item-info-text_bold {
    font-weight: 600;
    letter-spacing: 0;
}

.edit-products-popup__no-item {
    margin: auto 0 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 13px */
    letter-spacing: -0.78px;
    text-align: center;
}



.search-product-popup__item-delete {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-product-popup__item-delete-icon {
    width: 24px;
    height: 24px;
}

.search-product-popup__item-delete-icon-stroke {
    stroke: var(--input-label-color);
}

.edit-products-popup__item-draggable {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4px 4px 4px;
    column-gap: 2px;
    row-gap: 4px;
}

.edit-products-popup__item-draggable-icon {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--input-select-bg-color);
    transition: all 0.2s ease-in-out;
}

.edit-products-popup__item:hover .edit-products-popup__item-draggable-icon {
    background-color: var(--input-select-selected-bg-color);
}

.edit-products-popup__item_dragging .edit-products-popup__item-draggable-icon {
    background-color: var(--input-select-selected-bg-color);
}

@media (max-width: 880px) {
    .edit-products-popup {
        padding: 0 16px;
        box-sizing: border-box;
    }

    .edit-products-popup__item:hover .edit-products-popup__item-draggable-icon {
        background-color: var(--input-select-bg-color);
    }
}
.example-card__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.example-card__container_type_wide {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.example-card__img {
  width: 100%;
  object-fit: cover;
  background: var(--example-card-bg-color);
}

.example-card__container_type_wide .example-card__img {
  height: 268px;
}

.example-card__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.example-card__content-box:nth-child(2) {
  flex: auto;
}

.example-card__content-box_hidden {
  display: none;
}

.example-card__title {
  color: var(--example-card-text-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.32px;
}

.example-card__text {
  height: 100%;
  color: var(--example-card-text-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.3px;
}

.example-card__price-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.example-card__price-text {
  color: var(--example-card-price-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.32px;
}

.example-card__price-btn_type_icon .example-card__price-text {
  color: var(--text-on-contrast-color);
}

.example-card__price-btn {
  width: 100%;
  color: var(--example-card-btn-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 5px;
  background: var(--input-select-bg-color);
  box-sizing: border-box;
  padding: 12px 8px;
  transition: all 0.2s ease-in-out;
}

.example-card__price-btn_type_apart {
  width: fit-content;
  color: var(--text-on-contrast-color);
  gap: 12px;
  display: flex;
  align-items: center;
}

.example-card__price-btn_type_text {
  padding: 0;
  background: none;
  width: fit-content;
  color: var(--example-card-btn-color);
}

.example-card__price-btn_type_icon {
  background: var(--example-card-btn-color);
}

.example-card__price-btn-icon {
  width: 16px;
  height: 16px;
}

.example-card__price-btn-icon-fill {
  fill: var(--text-on-contrast-color);
}

@media (max-width: 700px) {
  .example-card {
    width: 50% !important;
  }

  .example-card_type_wide {
    width: 100% !important;
  }

  .example-card__container_type_wide .example-card__img {
    height: 200px;
  }
}

.show-case {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}

.show-case__products {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    border-top: 1px solid var(--table-separator-color);
    padding: 16px 0 8px;
    box-sizing: border-box;
}

.show-case__products-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;


    background-color: var(--secondary-bg-color);
    width: fit-content;
}

.how-case__products-random {
    /* text-align: center; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.9px;
}

.show-case__products-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 8px;
    width: 100%;
}

.show-case__products-item {
    width: 40px;
    height: 40px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-select-bg-color);
}



.show-case__products-item-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
}

.show-case__products-item-img-placeholder {
    background-color: var(--input-select-bg-color);
}
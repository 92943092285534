.zone-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.zone-form__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 13px */
    letter-spacing: -0.78px;
}



.zone-form__zones {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--input-border-color);
}

.zone-form__zones-heading {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 40px;
    grid-template-rows: max-content;
    column-gap: 32px;
    align-items: center;
    padding: 8px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--input-border-color);
}

.zone-form__zones-heading-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: -0.2px;
    opacity: 0.7;
}

.zone-form__zone-items {
    padding: 8px 12px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.zone-form__zone {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 40px;
    grid-template-rows: max-content;
    column-gap: 32px;
    align-items: center;
}

.zone-form__zone-number {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: var(--bg-color);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    text-align: center;
}

.zone-form__zone-value {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
    letter-spacing: -0.24px;
}

.zone-form__zone-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.zone-form__zone-edit-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
}


.zone-form__zone-edit {
    width: 16px;
    height: 16px;
}


.zone-form__zone-edit-icon {
    fill: var(--text-color);
}


.zone-form__zone-cross-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.zone-form__zone-cross {
    width: 20px;
    height: 20px;
}


.zone-form__zone-cross-icon {
    stroke: var(--text-color);
}

.zone-form__btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px 12px;

}

.zone-form__btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    letter-spacing: -0.48px;
    padding: 0 16px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    border-radius: 6px;
}




@media (max-width: 880px) {
    .zone-form__zones-heading {
        column-gap: 8px;
    }

    .zone-form__zone {
        column-gap: 8px;
    }
}
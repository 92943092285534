.order-page {
    display: flex;
    flex-direction: column;
}

.order-page__item-from-container{
    padding: 0;
}

.order-page__two-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 75vh;
}

.order-page__products-list{
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--table-separator-color);
    box-sizing: border-box;
    overflow: scroll;
    height: 100%;
}
.order-page__products-form{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
}

.order-page__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 16px; */
}

.order-page__more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.2s linear;
    background-color: var(--controls-bg-color);
  }
  
  .order-page__more-btn_disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
  }
  
  .order-page__more-btn-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.72px;
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);
    opacity: 0;
    visibility: hidden;
  }
  
  .order-page__more-btn-text_visible {
    opacity: 1;
    visibility: visible;
  }
  
  .order-page__btn-preloader {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
  }
  
  .order-page__btn-preloader_visible {
    opacity: 1;
    visibility: visible;
  }
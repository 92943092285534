.two-factor-btn {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}

.two-factor-btn__btn {
    width: 100%;
    padding: 14px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    border-radius: 8px;
    background-color: var(--two-factor-btn-bg-color);
    color: var(--two-factor-btn-text-color);

}

.two-factor-btn__confirm {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: -0.9px;
    color: var(--two-factor-btn-text-color);
    text-align: center;
}

.two-factor-btn__btn_cancel {
    background-color: var(--input-select-bg-color);
    color: var(--text-color);
}

.two-factor-btn__btns {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 8px;
}
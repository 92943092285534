.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.form-container__empty{
    width: 100%;
    min-height: 40px;
}
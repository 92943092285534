.transactions {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px 0 0;
    gap: 16px;
}

.transactions__more {
    width: fit-content;
    margin: 0 auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.2s linear;
    background-color: var(--controls-bg-color);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.72px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);

}

.transactions__more_loading {
    pointer-events: none;
    user-select: none;
}
.subscription {
    display: flex;
    max-width: 1120px;
    width: 100%;
    flex-direction: column;
    padding: 40px 80px;
    margin: 0 auto;
}

.subscription__titles-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 54px;
}

.subscription__titles-box__title_main {
    color: var( --text-color);
    font-family: Golos Text;
    font-size: 46px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    /* 86.957% */
    letter-spacing: -2.76px;
}

.subscription__titles-box__decs {
    color: var( --text-color);
    font-family: Golos Text;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.64px;
}

.subscription__cancel-subscription{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px 6px;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.2s linear;
    background-color: var(--btns-bg-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.72px;
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);
    margin: 24px 0 0 auto;
}

@media (max-width:1200px) {
    .subscription {
        padding: 30px;
        box-sizing: border-box;
    }
}

@media (max-width:900px) {
    .subscription {
        padding: 16px 16px 60px 16px;
    }

    .subscription__titles-box {
        flex-direction: column;
        gap: 8px;
    }

    .subscription__titles-box__title_main {
        font-size: 24px;
        font-weight: 400;
        line-height: 100%;
        /* 24px */
        letter-spacing: -1.44px;
    }

    .subscription__titles-box__decs {
        font-size: 13px;
        line-height: 18px;
        /* 138.462% */
        letter-spacing: -0.52px;
    }
}
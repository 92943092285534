.item-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 80px;

    box-sizing: border-box;
    margin: 16px 0 0;
}

.item-form__container {
    width: 100%;
    background-color: var(--secondary-bg-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 32px;
    border-radius: 16px;
    box-shadow: 0px 13px 26px 0px var(--box-shadow-color);
}

@media (max-width: 880px) {
 .item-form{
    padding: 0 8px;
 }
}
.form-controls {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  column-gap: 4px;
  opacity: 1;
  box-sizing: border-box;
  padding-bottom: 11px;
  transition: all 0.2s ease-in-out;
}

.form-controls__icon {
  width: 100%;
  height: 100%;
}

.form-controls__icon_type_reverse {
  transform: rotate(180deg);
}

.form-controls__icon_type_visibility {
  width: 18px;
  height: 10px;
  position: absolute;
}

.form-controls__icon_hidden {
  opacity: 0;
}

.form-controls__icon-fill {
  fill: var(--text-color);
}
